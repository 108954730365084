import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { IoHomeOutline } from "react-icons/io5";

export default function TopBanner() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[70px] flex items-center justify-between py-0 px-[5%] bg-[#2D66A8]">
      <Logo />
      <IoHomeOutline className="size-[40px] text-white cursor-pointer" onClick={()=>{
navigate('/')
      }}/>
    </div>
    
  );
}
