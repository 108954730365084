import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../style.css";
import { RiUserSettingsLine } from "react-icons/ri";
import { LuAlignRight, LuLayoutDashboard } from "react-icons/lu";
import AnimatedButton from "../AnimatedButton";
import Logo from "../Logo";
import { LuSmartphoneNfc } from "react-icons/lu";
import { TbLogout, TbNetwork } from "react-icons/tb";
import { BsBarChart } from "react-icons/bs";
import { PiWallet } from "react-icons/pi";
import { IoMdKeypad } from "react-icons/io";
import { MdPassword } from "react-icons/md";
import { RiDiscountPercentLine } from "react-icons/ri";
import { RxAvatar } from "react-icons/rx";

const Sidebar = ({ pinSet, logout, type }) => {
  const navigate = useNavigate();

  const mobile = document.querySelector(".mobile-sidebar");

  function mobileHideSide() {
    mobile.classList.toggle("hide");
  }

  return (
    <div className="mobile-sidebar">
      <div className="dashboard-sidebar" id="sidebar">
        <div className="dashboard-logo-container">
          <Logo />
          <div className="hide-sidebar">
            <AnimatedButton>
              <LuAlignRight
                id="mobile-hide-button"
                onClick={mobileHideSide}
                size={30}
                className="text-white cursor-pointer"
              />
            </AnimatedButton>
          </div>
        </div>
        <hr className="first-hr" />
        <div className="sidebar-links">
          {type === "dashboard" ? (
            <div className="sidebar-image-link first-sidebar-image-link">
              <div className="sidebar-link-image">
                <LuLayoutDashboard className="sidebar-link-image text-[#F55151]" />
              </div>
              <div className="sidebar-link">
                <Link className="" to="/dashboard">
                  Dashboard
                </Link>
              </div>
            </div>
          ) : (
            <div className="sidebar-image-link first-sidebar-image-link">
              <div className="sidebar-link-image">
                <RiUserSettingsLine className="sidebar-link-image text-[#F55151]" />
              </div>
              <div className="sidebar-link">
                <Link className="" to="">
                  Profile Settings
                </Link>
              </div>
            </div>
          )}
          {type === "dashboard" && (
            <div className="sidebar-image-link">
              <LuSmartphoneNfc className="sidebar-link-image text-[#F55151]" />
              <div className="sidebar-link pl-1">
                <Link className="" to="/airtime">
                  Buy Airtime
                </Link>
              </div>
            </div>
          )}
          {type === "dashboard" && (
            <div className="sidebar-image-link">
              <TbNetwork className="sidebar-link-image text-[#F55151]" />
              <div className="sidebar-link pl-1">
                <Link className="" to="/data">
                  Buy Data
                </Link>
              </div>
            </div>
          )}
          {type === "dashboard" && (
            <div className="sidebar-image-link">
              <TbNetwork className="sidebar-link-image text-[#F55151]" />
              <div
                className="sidebar-link pl-1 cursor-pointer text-white"
                onClick={() =>
                  navigate("/data", { state: { myNetwork: "GloBucket" } })
                }
              >
                Glo Bucket
              </div>
            </div>
          )}
          {type === "dashboard" && (
            <div className="sidebar-image-link">
              <div className="sidebar-link-image">
                <BsBarChart className="sidebar-link-image text-[#F55151]" />
              </div>
              <div className="sidebar-link">
                <Link className="" to="/transactions">
                  Transaction History
                </Link>
              </div>
            </div>
          )}
          {type === "dashboard" && (
            <div className="sidebar-image-link">
              <div className="sidebar-link-image">
                <PiWallet className="sidebar-link-image text-[#F55151]" />
              </div>
              <div className="sidebar-link pl-1">
                <Link className="" to="/wallet">
                  Wallet History
                </Link>
              </div>
            </div>
          )}
          {type === "profile" && (
            <div className="sidebar-image-link">
              <div className="sidebar-link-image">
                <IoMdKeypad className="sidebar-link-image text-[#F55151] font-bold" />
              </div>
              <div className="sidebar-link">
                {pinSet ? (
                  <Link className="" to="/changepin">
                    Change Pin
                  </Link>
                ) : (
                  <Link className="" to="/createpin">
                    Create Pin
                  </Link>
                )}
              </div>
            </div>
          )}
          {type === "profile" && (
            <div className="sidebar-image-link">
              <div className="sidebar-link-image">
                <MdPassword className="sidebar-link-image text-[#F55151] font-bold" />
              </div>
              <div className="sidebar-link">
                <Link className="" to="/changepassword">
                  Change Password
                </Link>
              </div>
            </div>
          )}
          {type === "profile" && (
            <div className="sidebar-image-link">
              <div className="sidebar-link-image">
                <RiDiscountPercentLine className="sidebar-link-image text-[#F55151] font-bold" />
              </div>
              <div className="sidebar-link">
                <Link className="" to="/discount">
                  Discounts
                </Link>
              </div>
            </div>
          )}
        </div>
        {type === "dashboard" ? (
          <div className="sidebar-image-link">
            <div className="sidebar-link-image">
              <RiUserSettingsLine size={25} className="text-[#F55151]" />
            </div>
            <div className="sidebar-link">
              <Link className="" to="/discount">
                Profile Settings
              </Link>
            </div>
          </div>
        ) : (
          <div className="sidebar-image-link">
            <div className="sidebar-link-image">
              <LuLayoutDashboard className="sidebar-link-image text-[#F55151]" />
            </div>
            <div className="sidebar-link">
              <Link className="" to="/dashboard">
                Dashboard
              </Link>
            </div>
          </div>
        )}{" "}
        <div className="sidebar-image-link">
          <div className="sidebar-link-image">
            <TbLogout className="sidebar-link-image text-[#F55151]" />
          </div>
          <div className="sidebar-link" onClick={() => logout()}>
            <Link className="" to="">
              Log Out
            </Link>
          </div>
        </div>
        <div className="dashboard-sidebar-bottom">
          <hr />
          <div className="sidebar-profile">
            <div className="dashboard-sidebar-profile-picture">
              <RxAvatar size={40} />
            </div>
            <div className="account-type-change">
              <div className="account-type-select">
                <p>Account Type</p>
              </div>
              <div className="account-type">Basic</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
