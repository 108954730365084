import React, { useEffect, useState } from "react";
// import Menus from './Menus'
// import Footers from './Footers'
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
// import { format } from 'date-fns'
// import Charts2 from './Charts/Charts2'
// import MenuTop from './MenuTop'
// import axios from 'axios'
// import ChartsInvestment from './Charts/ChartsInvestment'
// import TimeAgo from './TimeAgo'
// import Header2 from './Header2'
import $ from "jquery";
import "../style.css";
// import { hashKey, headers } from '../../utils/headers'
import AnimatedButton from "../AnimatedButton";
// import { RoundToTwo } from '../RoundToTwo'
import Sidebar from "./Sidebar";
import { LuAlignLeft } from "react-icons/lu";
import DataTable from "react-data-table-component";
import { IoSearch } from "react-icons/io5";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { LuSmartphoneNfc } from "react-icons/lu";
import { TbNetwork } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { FiRefreshCw } from "react-icons/fi";

// SEARCH COMPONENT
const FilterComponent = ({ filterText, onFilter }) => (
  <div className="absolute top-0 right-0 translate-y-[-100%] flex items-center text-[14px] lg:text-[16] p-2 lg:p-3 w-full lg:max-w-min ">
    <input
      id="search"
      type="text"
      className="p-1 lg:p-2 border border-[grey] rounded-l-[4px] outline-none w-full lg:w-auto h-[30px] lg:h-[40px]"
      placeholder="Search..."
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <div className="rounded-r-[4px] bg-[grey] size-[30px] lg:size-[40px] flex items-center justify-center">
      <IoSearch className="size-[20px] lg:size-[25px] text-white" />
    </div>
  </div>
);

const Index = (props) => {
  // const {
  //   capitalizeWords,
  //   isAuthExpired,
  //   isOffcanvasActive,
  //   handleMenuToggle,
  //   token,
  //   newNotify,
  //   getNotifyCounts,
  //   notifications,
  //   fetchNotifys,
  // } = props;

  // const { objectToFormData, isAdmins, setIsAdmins } = props;
  // const [updateTitle, setUpdateTitle] = useState("");
  // const [updateID, setUpdateID] = useState("");
  const [wallets, setWallets] = useState();
  const [transactions, setTransactions] = useState([]);
  const [username, setUsername] = useState();
  const [accountNo, setAccountNo] = useState();
  const [pinSet, setPinSet] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [viewBalance, setViewBalance] = useState(false);
  const [loading, setLoading] = useState(false);

  const modal = document.querySelector(".modal");

  function openModal() {
    //   modal.style.display = 'block'
    setModalActive(true);
  }
  function closeModal() {
    //   modal.style.display = 'none'
    setModalActive(false);
  }
  window.onclick = function (e) {
    if (e.target === modal) {
      setModalActive(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    let record = localStorage.getItem("userDataDani");
    if (record == undefined || record == null) {
      navigate("/login");
    } else {
      let records = JSON.parse(record);
      setUsername(records.name);
      setAccountNo(records.virtual_acct);
      let isPin = localStorage.getItem("create_pin");
      if (isPin == 1) {
        setPinSet(true);
      }
    }
  });

  useEffect(() => {
    document.title = "Dashboard | Bill Payment";
  }, []);

  // let overNet = "";
  var tosin = localStorage.getItem("token");
  const getDatas = async () => {
    try {
      setLoading(true);
      const response = await fetch(API_ROUTES.GET_WALLET, {
        method: "POST",
        headers: { token: tosin },
      });
      const responseJSON = await response.json();

      if (responseJSON.status == 200) {
        const responseData = responseJSON.balance;
        setWallets(responseData);
        setLoading(false);
      } else {
        setLoading(false);
        logout();
      }
    } catch (error) {
      console.log("Error getting data: ", error);
      setWallets(0);
      setLoading(false);
    }
  };

  const getTransactions = async () => {
    var raw = JSON.stringify({});
    try {
      const response = await fetch(API_ROUTES.GET_TRANSACTIONS, {
        method: "POST",
        body: raw,
        headers: { token: tosin },
      });
      const responseJSON = await response.json();

      if (responseJSON.status == 200) {
        const responseData = responseJSON.data;
        setTransactions(responseData);
      } else {
        logout();
      }
    } catch (error) {
      console.log("Error getting data: ", error);
      setWallets(0);
    }
  };

  useEffect(() => {
    getDatas();
    getTransactions();
    // fetchNotifys();
  }, []);

  // const openLink = (page) => {
  //   navigate(`/${page}/`);
  // };

  // const showBuyDiv = (title, id) => {
  //   // setUpdateTitle(title);
  //   // setUpdateID(id);
  //   $(".ntwk_div").slideUp("fast");
  //   $(".amount_div, button.hidden").slideUp("fast");

  //   $(".txtEnterAmt").val("");
  //   $(".amount_div input").val("");
  // };

  // $(document).on('click', '.billers input', function(e){
  //     var network = $(this).val();
  //     var isChecked = $(this).is(":checked");
  //     $('.ntwk_div').slideUp('fast');

  //     if(isChecked == true){
  //         $('.ntwk_div').slideDown('fast');
  //     }
  // });

  // const [showNetworkDiv, setShowNetworkDiv] = useState(false);

  // const handleCheckboxClick = (e) => {
  //     const isChecked = e.target.checked;
  //     setShowNetworkDiv(isChecked);
  // };

  const logout = () => {
    localStorage.removeItem("userDataDani");
    // localStorage.removeItem('admin_priv');
    // localStorage.removeItem('admin_roles');
    navigate("/login");
  };

  // const walletBalance = parseFloat(wallets).toLocaleString().toFixed(2)

  // const walletBalance = wallets;

  // const text = document.getElementById('hide-button')
  const sidebar = document.querySelector(".dashboard-sidebar");
  const dashboardMain = document.querySelector(".dashboard");
  const dashboardMainSection = document.querySelector(
    ".dashboard-main section"
  );
  const mobile = document.querySelector(".mobile-sidebar");

  function hideSide() {
    sidebar.classList.toggle("hide");
    dashboardMain.classList.toggle("hide");
    dashboardMainSection.classList.toggle("hide");
  }

  function showSide() {
    mobile.classList.toggle("hide");
  }

  const toggleBalanceVisibility = () => {
    setViewBalance((prev) => !prev);
  };
  const columns = [
    {
      name: "Date",
      sortable: true,
      center: true,
      selector: (row) => row.created_at,
    },
    {
      name: "Reference",
      sortable: true,
      center: true,
      selector: (row) => row.reference,
    },
    {
      name: "Recipient",
      sortable: true,
      center: true,
      selector: (row) => row.destination,
    },
    {
      name: "Amount",
      sortable: true,
      center: true,
      selector: (row) => `₦${parseFloat(row.amount).toLocaleString()}`,
    },
    {
      name: "Description",
      sortable: true,
      center: true,
      selector: (row) => row.description,
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      selector: (row) => {
        if (row.status === "1") {
          return "Successful";
        } else if (row.status === "0") {
          return "Pending";
        } else if (row.status === "2") {
          return "Failed";
        }
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        justifyContent: "flex-end",
      },
    },
    headCells: {
      style: {
        fontSize: "17px",
        fontWeight: 800,
      },
    },
    cells: {
      style: {
        fontSize: "16px",
        paddingRight: "10px",
      },
    },
  };

  // SEARCH
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = transactions.filter(
    (item) =>
      (item.amount && item.amount.toString().includes(filterText)) ||
      (item.destination && item.destination.toString().includes(filterText)) ||
      (item.balance_after &&
        item.balance_after.toString().includes(filterText)) ||
      (item.description &&
        item.description.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleFilter = (event) => {
    setFilterText(event.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleCopy = (number) => {
    navigator.clipboard.writeText(number.toString());
  };

  return (
    <>
      <Sidebar pinSet={pinSet} logout={logout} type={"dashboard"} />

      <div
        className="modal"
        style={{ display: modalActive ? "block" : "none" }}
      >
        {" "}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title text-3xl"
                id="exampleModalLabel"
                style={{ fontWeight: "bold" }}
              >
                How To Fund
              </h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => closeModal()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="text-xl mb-[20px]">
                {" "}
                Kindly fund your wallet from any bank with the following details
              </div>
              <table className="m-auto border-separate border-spacing-2 w-full text-lg">
                <tbody>
                  <tr>
                    <td className="p-2 text-right bg-slate-400 rounded-lg text-white">
                      Bank Name
                    </td>
                    <td className="p-2 text-left">Taj_Pinspay</td>
                  </tr>
                  <tr>
                    <td className="p-2 text-right bg-slate-400 rounded-lg text-white">
                      Account Name&nbsp;
                    </td>
                    <td className="p-2 text-left">{username}</td>
                  </tr>
                  <tr>
                    <td className="p-2 text-right bg-slate-400 rounded-lg text-white">
                      Account Number
                    </td>
                    <td className="p-2 text-left">{accountNo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => closeModal()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  handleCopy(accountNo);
                }}
              >
                Copy Account Number
              </button>
            </div>
          </div>
        </div>
      </div>
      <main className="dashboard">
        <div className="dashboard-main">
          <header
            style={{
              backgroundColor: "rgba(249, 249, 249, 1)",
            }}
          >
            <div className="hide-sidebar">
              <button id="hide-button" className="hidden">
                Hide
              </button>
              <div
                className="w-[100vw]"
                style={{
                  backgroundColor: "rgba(249, 249, 249, 1)",
                }}
              >
                <AnimatedButton className="w-min">
                  <LuAlignLeft
                    onClick={hideSide}
                    size={45}
                    className="text-[#2D66A8] cursor-pointer"
                    id="hide-button"
                  />
                </AnimatedButton>
              </div>{" "}
              <div
                className="w-[100vw] translate-y-[-4px]"
                style={{
                  backgroundColor: "rgba(249, 249, 249, 1)",
                }}
              >
                <AnimatedButton className="w-min">
                  <LuAlignLeft
                    onClick={showSide}
                    size={30}
                    className="text-[#2D66A8] cursor-pointer"
                    id="show-button"
                  />
                </AnimatedButton>
              </div>
            </div>
          </header>
          <section>
            <div className="dashboard-content">
              <div className="content-label mt-[50px] mb-[10px] lg:my-[10px]">
                <p>Dashboard</p>
                <AnimatedButton
                  className="add-money cursor-pointer ml-auto"
                  onClick={() => openModal()}
                >
                  <div className="add-logo">
                    <img src="assets/add-money.png" alt="" />
                  </div>
                  <p>Add Funds</p>
                </AnimatedButton>
              </div>
              <div className="content-main">
                <div className="welcome-balance relative">
                  <div
                    onClick={() => getDatas()}
                    className={`absolute right-[30px] cursor-pointer ${
                      loading && "rotating"
                    }`}
                  >
                    <FiRefreshCw size={30} />
                  </div>
                  <div
                    className="absolute bottom-[30px] right-[30px] cursor-pointer"
                    onClick={toggleBalanceVisibility}
                  >
                    {viewBalance ? (
                      <FaRegEye size={30} />
                    ) : (
                      <FaRegEyeSlash size={30} />
                    )}
                  </div>
                  <div className="welcome-name">
                    <p>
                      Welcome,
                      <br />
                      {username}
                    </p>
                  </div>
                  {/* <div className="row" style={{ marginTop: '-5px' }}>
                    <font style={{ color: '#999', fontSize: '13px' }}>
                      <b>A/C:</b> {accountNo}
                    </font>
                    <br />
                    <font style={{ color: '#999', fontSize: '13px' }}>
                      <b>Bank:</b> Taj_pinspay
                    </font>
                  </div> */}
                  <div className="welcome-balance-amount">
                    <p>Balance</p>
                    <p className="bold flex items-center mt-[10px]">
                      {" "}
                      {!viewBalance ? "****" : `₦${wallets}`}
                    </p>
                  </div>
                </div>
                <AnimatedButton
                  className="dashboard-buy-airtime cursor-pointer group hover:bg-[#2D66A8]"
                  onClick={() => navigate("/airtime")}
                >
                  <LuSmartphoneNfc className="airtime-phone group-hover:text-white" />
                  <div className="airtime-base-text">
                    <p className="bold red group-hover:text-white">
                      Buy <br />
                      Airtime
                    </p>
                    <Link className="" to="/airtime/">
                      <div className="next">
                        <IoIosArrowForward className="next-btn group-hover:text-white text-[#FF0000]" />
                      </div>
                    </Link>
                  </div>
                </AnimatedButton>
                <AnimatedButton
                  className="dashboard-buy-data cursor-pointer group hover:bg-[#2D66A8]"
                  onClick={() => navigate("/data")}
                >
                  <TbNetwork className="data-phone group-hover:text-white" />
                  <div className="airtime-base-text">
                    <p className="bold blue group-hover:text-white">
                      Buy <br />
                      Data
                    </p>
                    <div className="next">
                      <IoIosArrowForward className="next-btn group-hover:text-white text-[#2D66A8]" />
                    </div>
                  </div>
                </AnimatedButton>
              </div>
              <AnimatedButton
                className="dashboard-buy-data cursor-pointer group hover:bg-[#008000]"
                onClick={() =>
                  navigate("/data", { state: { myNetwork: "GloBucket" } })
                }
              >
                <TbNetwork className="data-phone text-[#008000] group-hover:text-white" />
                <div className="airtime-base-text">
                  <p className="bold text-[#008000] group-hover:text-white">
                    Glo <br />
                    Bucket
                  </p>
                  <div className="next">
                    <IoIosArrowForward className="next-btn group-hover:text-white text-[green]" />
                  </div>
                </div>
              </AnimatedButton>

              {/* <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Launch demo modal
              </button> */}

              {/* <!-- Modal --> */}
              {/* <div
                className="modal fade"
                id="exampleModal"
                // tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ zIndex : '1000000000000' }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Modal title
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">...</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div>
              <div className="transaction-table" id="transaction-table">
                <div className="pt-[70px] mb-[50px] text-[28px] font-bold lg:mb-[19px] leading-[34px] text-[#585858]">
                  Recent Transactions
                </div>
                <div className="relative mt-[20px] lg:mt-0">
                  <FilterComponent
                    filterText={filterText}
                    onFilter={handleFilter}
                  />
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    customStyles={customStyles}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                  />
                </div>
              </div>
            </div>

            <div className="fixed bottom-[10vh] right-0 z-[-1]">
              <div className="flex">
                <AnimatedButton className="cursor-pointer">
                  <img
                    src="../assets/9mobile 1.png"
                    alt=""
                    className="n-mobile"
                  />
                </AnimatedButton>
                <AnimatedButton className="cursor-pointer">
                  <img src="../assets/Airtel 3.png" alt="" className="airtel" />
                </AnimatedButton>
              </div>
              <div className="flex">
                <AnimatedButton className="cursor-pointer">
                  <img src="../assets/glo.png" alt="" className="glo" />
                </AnimatedButton>
                <AnimatedButton className="cursor-pointer">
                  <img src="../assets/MTN 2.png" alt="" className="mtn" />
                </AnimatedButton>
              </div>
            </div>
          </section>
        </div>
        <div className="background">
          <img src="assets/dashboard-back.png" alt="" />
        </div>
      </main>
    </>
  );
};

export default Index;
