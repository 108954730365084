import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../utils/constants";
import "./style.css";
import registerImg from "./assets/register.svg";
import TopBanner from "./TopBanner";
import AnimatedButton from "./AnimatedButton";
// import appLogo from '../images/loripay_logo.png';
// import loginBG from '../images/login3.png';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

export default function Register(props) {
  useEffect(() => {
    document.title = "Login | noveLT";
  }, []);

  const { objectToFormData, isAdmins, setIsAdmins } = props;
  const rememberLogin = localStorage.getItem("rememberLoginFs");
  const rememberMe = rememberLogin ? true : false;
  const userInput = localStorage.getItem("userInputFs");
  const inputObject = JSON.parse(userInput);
  const isEmail = inputObject ? atob(inputObject.email) : "";
  const isPassword = inputObject ? atob(inputObject.password) : "";

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: rememberMe ? isEmail : "",
      password: rememberMe ? isPassword : "",
      remember: rememberMe,
    },
  });
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: {
      errors: errors1,
      isSubmitting: isSubmitting1,
      isValid: isValid1,
    },
  } = useForm({
    mode: "onChange",
  });

  const [isChecked, setIsChecked] = useState(rememberMe);
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState(null);
  const [passwordr, setPasswordr] = useState("");
  const [email, setEmail] = useState("");
  const [emailr, setEmailr] = useState("");
  const [phone, setPhone] = useState("");
  const [confirm, setConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showReset, setShowReset] = useState(false);
  const [showCreateAcct, setShowCreateAcct] = useState(false);
  const navigate = useNavigate();

  const showFormReset = () => {
    setShowLoginForm(false);
    setShowReset(true);
    setShowCreateAcct(false);
  };
  const showFormLogin = () => {
    setShowLoginForm(true);
    setShowReset(false);
    setShowCreateAcct(false);
  };

  const createAcct = () => {
    setShowLoginForm(false);
    setShowReset(false);
    setShowCreateAcct(true);
  };

  const setLocalStorageAndNavigate = (responseDetails, data) => {
    const userData = {};
    userData.isLoggedIn = true;
    userData.responseDetails = responseDetails;
    localStorage.setItem("token", responseDetails._token);

    localStorage.setItem(
      "userDataDani",
      JSON.stringify(responseDetails.record)
    );
    navigate("/dashboard");
  };

  const loginAuth = async (data) => {
    // navigate("/dashboard");
    console.log(email);
    data = { email: email, password: password };
    try {
      const response = await fetch(API_ROUTES.SIGN_IN, {
        method: "POST",
        body: objectToFormData(data),
      });
      const responseJSON = await response.json();
      const message = responseJSON.message;

      if (responseJSON.status.toString() === "200") {
        const responseDetails = responseJSON.data;

        setLocalStorageAndNavigate(responseDetails, data);
        setIsAdmins(responseJSON.data.roles);
        localStorage.setItem("admin_roles", responseJSON.data.roles);
      } else {
        Swal.fire({
          title: "<font className='error_title'>Error</font>",
          html: `${message}`,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        html: error,
        title: "Error",
      });
    }
  };

  const submitRequests = async (data) => {
    if (
      emailr == "" ||
      passwordr == "" ||
      firstName == "" ||
      lastName == "" ||
      phone == "" ||
      confirm == ""
    ) {
      Swal.fire({
        title: "<font className='error_title'>Error</font>",
        html: "Fill all fields correctly",
        icon: "error",
      });
      return;
    }

    if (passwordr !== confirm) {
      Swal.fire({
        title: "<font className='error_title'>Error</font>",
        html: "Passwords must match",
        icon: "error",
      });
      return;
    }
    data = {
      email: emailr,
      password: passwordr,
      name: `${firstName} ${lastName}`,
      phone_number: phone,
      confirm_password: confirm,
    };
    try {
      const response = await fetch(API_ROUTES.REGISTER, {
        method: "POST",
        body: objectToFormData(data),
      });
      const responseJSON = await response.json();
      if (responseJSON.status.toString() === "200") {
        Swal.fire({
          title: "Success",
          html: responseJSON.message,
          icon: "success",
        });
        reset1();
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        Swal.fire({
          title: "<font className='error_title'>Error</font>",
          html: responseJSON.message,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        html: error,
        title: "Error",
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleCPasswordVisibility = () => {
    setShowCPassword((prev) => !prev);
  };

  return (
    <div className="one-body h-[100vh]">
      <TopBanner />
      <div className="flex items-center w-full">
        <div className="sign-up-main">
          <div className="sign-up-body">
            <p className="sign-in-main-text-one md:hidden pl-[10px]">Sign Up</p>
            <div className="sign-in-one mb-2 hidden md:flex">
              <div className="sign-in-main-one">
                <p className="sign-in-main-text-one">Sign Up</p>
                <p className="sign-in-sub-text">
                  Experience unparalleled airtime and data purchase services
                </p>
              </div>
              <div className="base"></div>
            </div>
            <div className="auth-content">
              <div className="sign-in-form">
                <div className="flex md:flex-row flex-col gap-2">
                  <div className="flex-1">
                    <label htmlFor="firstName">First Name</label>
                    <div className="">
                      <input
                        className="w-full"
                        type="text"
                        name=""
                        id="firstName"
                        value={firstName}
                        onInput={(text) => setFirstName(text.target.value)}
                        placeholder="Enter your fist name"
                      />
                    </div>
                  </div>
                  <div className="flex-1">
                    <label htmlFor="lastName">Last Name</label>
                    <div className="">
                      <input
                        type="text"
                        id="lastName"
                        className="w-full"
                        value={lastName}
                        onInput={(text) => setLastName(text.target.value)}
                        name="lastName"
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="fullname">
                  <label htmlFor="fullname">Full Name</label>
                  <div className="mt-1 w-full">
                    <input
                      className="w-full"
                      type="text"
                      name=""
                      value={name}
                      onInput={(text) => setName(text.target.value)}
                      id=""
                      placeholder="Full Name"
                    />
                  </div>
                </div> */}
                <br />
                <div className="flex md:flex-row flex-col gap-2">
                  <div className="password flex-1">
                    <label htmlFor="email">Email Address</label>
                    <div className="password-input">
                      <input
                        className="w-full"
                        type="email"
                        name=""
                        id="email"
                        value={emailr}
                        onInput={(text) => setEmailr(text.target.value)}
                        placeholder="example@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="phonee flex-1">
                    <label htmlFor="phone">Phone Number</label>
                    <div className="phone-input">
                      <span className="country-code">+234:</span>
                      <input
                        type="tel"
                        id="phone"
                        value={phone}
                        onChange={(text) => setPhone(text.target.value)}
                        name="phone"
                        placeholder="804 *** ****"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex md:flex-row flex-col gap-2">
                  <div className="password">
                    <label htmlFor="password">Password</label>
                    <div className="phone-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        name=""
                        value={passwordr}
                        onChange={(text) => setPasswordr(text.target.value)}
                        id="password"
                        placeholder="*********"
                      />
                      <div
                        className="cursor-pointer password-image "
                        onClick={() => togglePasswordVisibility()}
                      >
                        {showPassword ? (
                          <FaRegEyeSlash className="text-black size-[18px]" />
                        ) : (
                          <FaRegEye className="text-black size-[18px]" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="password">
                    <label htmlFor="cpassword">Confirm Password</label>
                    <div className="phone-input">
                      <input
                        type={showCPassword ? "text" : "password"}
                        name=""
                        value={confirm}
                        onChange={(text) => setConfirm(text.target.value)}
                        id="cpassword"
                        placeholder="*********"
                      />
                      <div
                        className="cursor-pointer password-image"
                        onClick={() => toggleCPasswordVisibility()}
                      >
                        {showCPassword ? (
                          <FaRegEyeSlash className="text-black size-[18px]" />
                        ) : (
                          <FaRegEye className="text-black size-[18px]" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="base">
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      width: "100%",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    {/* <AnimatedButton
                      onClick={() => navigate("/login")}
                      className="cursor-pointer go-button"
                    >
                      Sign In
                    </AnimatedButton> */}
                    <p className="create-account">
                      Already have an account? click{" "}
                      <Link className="bold link" to="/login">
                        here
                      </Link>{" "}
                      to sign in
                    </p>
                    <AnimatedButton
                      className="cursor-pointer go-button ml-auto"
                      onClick={submitRequests}
                    >
                      Let's Go
                    </AnimatedButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block size-[400px]">
          <img src={registerImg} alt="" />
        </div>
      </div>
    </div>
  );
}
