import React from "react";
import { useNavigate } from "react-router-dom";
import { FaHouseChimney } from "react-icons/fa6";

export default function Logo() {
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center gap-[10px] cursor-pointer"
      onClick={() => {
        navigate("/");
      }}
    >
      <div className="flex flex-col justify-center">
        <div className="size-[15px] lg:size-[20px] rounded-sm border-[2px] border-white rotate-45"></div>
        <div className="size-[15px] lg:size-[20px] rounded-sm border-[2px] border-white rotate-45 translate-y-[-50%]"></div>
      </div>
      <div className="text-xl lg:text-2xl text-white font-mono font-bold select-none">
        noveLT
      </div>
      {/* <div className="flex justify-end">
        <div className="flex items-center">
          <FaHouseChimney
            className="text-white mt-2"
            style={{ width: "200px" }}
          />
          <span>Go Home</span>
        </div>
      </div> */}
    </div>
  );
}
