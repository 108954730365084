import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimatedButton from "../AnimatedButton";
import Sidebar from "./Sidebar";
import { LuAlignLeft } from "react-icons/lu";
import Swal from "sweetalert2";
import { API_ROUTES } from "../../utils/constants";
import nMobile from "../assets/9mobile1.png";
import airtel from "../assets/Airtel3.png";
import glo from "../assets/Glo 1.png";
import mtn from "../assets/MTN 2.png";

export default function Discount() {
  const navigate = useNavigate();
  const [pinSet, setPinSet] = useState(false);
  // const [username, setUsername] = useState();
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    document.title = "Discount | noveLT";
  }, []);

  useEffect(() => {
    let record = localStorage.getItem("userDataDani");
    if (record == undefined || record == null) {
      navigate("/login");
    } else {
      // let records = JSON.parse(record);
      // setUsername(records.name);
      let isPin = localStorage.getItem("create_pin");
      if (isPin == 1) {
        setPinSet(true);
      }
    }
  });

  useEffect(() => {
    async function getDiscounts() {
      try {
        const tosin = localStorage.getItem("token");
        // const myHeaders = new Headers();
        // myHeaders.append("token", tosin);
        // myHeaders.append("Content-Type", "application/json");

        const response = await fetch(API_ROUTES.GET_DISCOUNTS, {
          method: "POST",
          body: JSON.stringify({}),
          headers: { token: tosin },
        });
        const responseJSON = await response.json();

        if (responseJSON.status.toString() === "200") {
          setDiscounts(responseJSON.data);
          console.log(discounts);
        } else {
          logout();
        }
      } catch (error) {
        console.log("Error getting data: ", error);
        Swal.fire({
          title: "Error",
          text: error || "Network error!",
          icon: "error",
        });
      }
    }
    getDiscounts();
  }, []);

  const logout = () => {
    localStorage.removeItem("userDataDani");
    navigate("/login");
  };

  const networkImages = {
    glo: glo,
    mtn: mtn,
    airtel: airtel,
    "9mobile": nMobile,
  };

  // SIDEBAR
  const [openSideBar, setOpenSideBar] = useState(true);

  const sidebar = document.querySelector(".dashboard-sidebar");
  const mobile = document.querySelector(".mobile-sidebar");

  function hideSide() {
    sidebar.classList.toggle("hide");
    setOpenSideBar((prev) => !prev);
  }
  function showSide() {
    mobile.classList.toggle("hide");
  }

  const getContainerStyle = () => {
    const baseStyle = {
      transition: ".5s ease",
    };

    if (window.innerWidth >= 1024) {
      baseStyle.marginLeft = openSideBar ? "16%" : "0px";
    } else {
      baseStyle.marginLeft = "0px";
    }

    return baseStyle;
  };

  const formatKey = (key) => {
    return key
      .split("_") // Split the string by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words with a space
  };
  const formatNet = (key) => {
    return key.split("_")[0];
  };

  return (
    <div>
      <Sidebar logout={logout} pinSet={pinSet} type={"profile"} />

      <div
        className="airtime-vending-body lg:ml-[16%] min-h-[100vh] max-h-[100vh] overflow-auto relative"
        style={getContainerStyle()}
      >
        {" "}
        <AnimatedButton className="w-min absolute top-[5%] left-[5%]">
          <LuAlignLeft
            onClick={hideSide}
            size={45}
            className="text-[#2D66A8] cursor-pointer"
            id="hide-button"
          />
        </AnimatedButton>{" "}
        <LuAlignLeft
          onClick={showSide}
          size={30}
          className="text-[#2D66A8] cursor-pointer fixed top-[4%] left-[5%]"
          id="show-button"
        />
        <div className="main h-[100%]">
          <div className="main-heading red">Discounts</div>
          {discounts.length < 1 ? (
            <p className="h-screen flex items-center justify-center text-xl md:text-3xl">
              You have no discounts
            </p>
          ) : (
            <div className="flex flex-wrap gap-[20px] justify-center mt-[20px]">
              {discounts.map((data, index) => {
                const img = formatNet(data.name);
                return (
                  <div
                    key={index}
                    className="py-[50px] px-[20px] gap-[20px] relative w-full max-w-[300px] bg-white rounded-[8px] border border-black flex flex-col"
                  >
                    <div className="absolute right-0 opacity-30">
                      <img
                        src={networkImages[img]}
                        alt=""
                        className="object-cover h-[120px]"
                      />
                    </div>
                    <div className="self-start text-lg md:text-2xl">
                      {formatKey(data.name)}
                    </div>
                    <div className="self-start text-xl md:text-3xl font-bold">
                      {parseInt(data.value)}%
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
