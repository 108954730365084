// import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import Swal from "sweetalert2";
import "./style.css";
import "./style1.css";
import { useNavigate } from "react-router-dom";
// import { API_ROUTES } from "../utils/constants";
import TopBanner from "./TopBanner";
import Logo from "./Logo";

export default function Contact(props) {
  const navigate = useNavigate();

  return (
    <div className="">
      <TopBanner />
      <div className="contact-us-main">
        <div className="contact-us">
          <p className="head">
            {" "}
            Contact <span>Us</span>
          </p>
          <p className="sub">
            Please leave us a message and someone from our team will get back to
            you within the shortest time possible. We are also available to help
            resolve any/all your queries or enquiries.
          </p>
        </div>
        <form action="" className="contact-form">
          <label htmlFor="full-name">Full name</label>
          <input
            type="text"
            name=""
            id="full-name"
            placeholder="Enter your full name"
          />
          <br />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name=""
            id="email"
            placeholder="Enter your email"
          />
          <br />
          <label htmlFor="message">Message</label>
          <input
            type="text"
            name=""
            id="message"
            placeholder="Enter your message"
          />
          <br />
          <button type="submit" className="send">
            Send
          </button>
        </form>
      </div>
      <footer>
        <div className="footer-col">
          <Logo />
          <div className="small-text">
            Treat yourself to affordable and reliable air time & data bundles
            with instant delivery across all networks.
          </div>
        </div>
        <div className="footer-col">
          <h6>Quick Links</h6>
          <div className="footer-col2-bottom">
            <div
              className="small-text"
              onClick={() => {
                navigate("/");
              }}
            >
              About Us
            </div>
            <div
              className="small-text"
              onClick={() => {
                navigate("/");
              }}
            >
              Service
            </div>
            <div className="small-text">Pricing</div>
          </div>
        </div>
        <div className="footer-col">
          <h6>Contact Us</h6>
          <div className="footer-col3-bottom">
            <div className="footer-col3-row">
              <img src="../assets/mail-icon.png" alt="" />
              <div className="small-text">hello@novelt.net</div>
            </div>
            <div className="footer-col3-row">
              <img src="../assets/location-icon.png" alt="" />
              <div className="small-text">
                Riverside Building, County Hall, GRA, Ikeja, Lagos, Nigeria.
              </div>
            </div>
            <div className="footer-col3-row">
              <img src="../assets/phone-icon.png" alt="" />
              <div className="small-text">+234 8121234560</div>
            </div>
          </div>
        </div>
        <div className="footer-col">
          <h6>Follow Us</h6>
          <div className="footer-socials">
            <img src="../assets/footer-twitter.png" alt="" />
            <img src="../assets/footer-Ig.png" alt="" />
            <img src="../assets/footer-FB.png" alt="" />
            <img src="../assets/footer-You tube.png" alt="" />
          </div>
        </div>
        <div className="footer-bottom">© 2024 noveLT. All rights served.</div>
      </footer>
    </div>
  );
}
