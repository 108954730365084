import React, { useEffect, useState } from "react";
// import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import "../style.css";
import Sidebar from "./Sidebar";
import AnimatedButton from "../AnimatedButton";
import { LuAlignLeft } from "react-icons/lu";
// import appLogo from '../images/loripay_logo.png';
// import loginBG from '../images/login3.png';

export default function ChangePin(props) {
  useEffect(() => {
    document.title = "Create Pin | noveLT";
  }, []);

  useEffect(() => {
    let record = localStorage.getItem("userDataDani");
    if (record == undefined || record == null) {
      navigate("/login");
    } else {
      // let records = JSON.parse(record)
      // setUsername(records.name)
      let isPin = localStorage.getItem("create_pin");
      if (isPin == 1) {
        setPinSet(true);
      }
    }
  });

  const logout = () => {
    localStorage.removeItem("userDataDani");
    // localStorage.removeItem('admin_priv');
    // localStorage.removeItem('admin_roles');
    navigate("/login");
  };

  // const [email, setEmail] = useState('')
  const [confirmPin, setConfirmPin] = useState("");
  // const [username, setUsername] = useState()
  const [pin, setPin] = useState();
  // const [isbusy, setIsBusy] = useState(false)
  // const [pinCreated, setPinCreated] = useState(false)
  const [pinSet, setPinSet] = useState(false);
  const navigate = useNavigate();
  const [oldPin, setOldPin] = useState("");

  // const userData = localStorage.getItem('userDataDani')
  // const userObj = JSON.parse(userData)
  // const userObj = userData;
  // const isAuthenticated = {
  //   isLoggedIn: userObj !== null ? userObj.isLoggedIn : false,
  // }

  // const setLocalStorageAndNavigate = (responseDetails, data) => {
  //   const userData = {}
  //   userData.isLoggedIn = true
  //   userData.responseDetails = responseDetails
  //   localStorage.setItem('token', responseDetails._token)

  //   localStorage.setItem('userDataDani', JSON.stringify(responseDetails.record))

  //   navigate('/dashboard')
  // }

  const changePIN = async (data) => {
    try {
      if (oldPin == "") {
        Swal.fire({
          title: "Error",
          text: "Please enter a valid old PIN",
          icon: "error",
        });
        return false;
      }
      if (pin !== confirmPin) {
        Swal.fire({
          title: "Error",
          text: "The two " + pin + " " + confirmPin + " must match",
          icon: "error",
        });
        return false;
      }

      const raw = JSON.stringify({
        oldPin: oldPin,
        newPin: pin,
        confirmPin: confirmPin,
      });

      const tosin = localStorage.getItem("token");

      const myHeaders = new Headers();
      myHeaders.append("token", tosin);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(API_ROUTES.CHANGE_PIN, requestOptions);
      const responseJSON = await response.json();

      if (responseJSON.status.toString() === "200") {
        Swal.fire({
          title: "Successful",
          text: responseJSON.message,
          icon: "success",
        });
        // setPinCreated(true)
        localStorage.setItem("create_pin", 1);
        navigate("/dashboard");
      } else {
        Swal.fire({
          title: "Error",
          text:
            responseJSON.message ||
            "An error occurred during the POST request.",
          icon: "error",
        });
      }
    } catch (error) {
      console.log("Error getting data: ", error);
      Swal.fire({
        title: "Error",
        text: error || "Network error!",
        icon: "error",
      });
    }
  };
  // SIDEBAR
  const [openSideBar, setOpenSideBar] = useState(true);

  const sidebar = document.querySelector(".dashboard-sidebar");
  const mobile = document.querySelector(".mobile-sidebar");

  function hideSide() {
    sidebar.classList.toggle("hide");
    setOpenSideBar((prev) => !prev);
  }
  function showSide() {
    mobile.classList.toggle("hide");
  }

  const getContainerStyle = () => {
    const baseStyle = {
      transition: ".5s ease",
    };

    if (window.innerWidth >= 1024) {
      baseStyle.marginLeft = openSideBar ? "16%" : "0px";
    } else {
      baseStyle.marginLeft = "0px";
    }

    return baseStyle;
  };

  return (
    <>
      <Sidebar logout={logout} pinSet={pinSet} type={"profile"} />

      <div
        className="airtime-vending-body lg:ml-[16%] min-h-[100vh] max-h-[100vh] overflow-auto relative"
        style={getContainerStyle()}
      >
        <AnimatedButton className="w-min absolute top-[5%] left-[5%]">
          <LuAlignLeft
            onClick={hideSide}
            size={45}
            className="text-[#2D66A8] cursor-pointer"
            id="hide-button"
          />
        </AnimatedButton>{" "}
        <LuAlignLeft
          onClick={showSide}
          size={30}
          className="text-[#2D66A8] cursor-pointer fixed top-[4%] left-[5%]"
          id="show-button"
        />
        <div className="main h-[100%]">
          <div className="main-heading red">Change your Transaction Pin</div>
          <div className="purchase-option">
            <AnimatedButton>
              <button
                onClick={() => navigate("/dashboard")}
                className="buy-airtime hover:text-[#FF0000]"
              >
                Dashboard
              </button>
            </AnimatedButton>
          </div>
          <div className="purchase-dashboard">
            <AnimatedButton className="next-button">
              <button onClick={() => changePIN()}>Submit</button>
            </AnimatedButton>
            <div className="airtime-form">
              <label htmlFor="current-pin">Current Pin</label>
              <input
                type="number"
                maxLength={4}
                id="current-pin"
                onInput={(e) => setOldPin(e.target.value)}
                placeholder="enter your current pin"
              />
              <br />
              <br />
              <label htmlFor="new-pin">New Pin</label>
              <input
                type="number"
                maxLength={4}
                name="new-pin"
                id="new-pin"
                onInput={(e) => setPin(e.target.value)}
                placeholder="enter your new pin"
              />
              <br />
              <br />
              <label htmlFor="confirm-pin">Confirm Pin</label>
              <input
                type="number"
                maxLength={4}
                id="confirm-pin"
                onInput={(e) => setConfirmPin(e.target.value)}
                placeholder="enter your pin again"
              />
            </div>
          </div>
          <div className="hidden lg:block fixed bottom-[15vh] right-0 z-[0]">
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img
                  src="../assets/9mobile 1.png"
                  alt=""
                  className="n-mobile"
                />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/Airtel 3.png" alt="" className="airtel" />
              </AnimatedButton>
            </div>
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/glo.png" alt="" className="glo" />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/MTN 2.png" alt="" className="mtn" />
              </AnimatedButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
