import React, { useEffect, useState } from 'react'
// import Menus from './Menus'
// import Footers from './Footers'
import { Link, useNavigate } from 'react-router-dom'
import { API_ROUTES } from '../../utils/constants'
// import { format } from 'date-fns'
// import Charts2 from './Charts/Charts2'
// import MenuTop from './MenuTop'
// import axios from 'axios'
// import ChartsInvestment from './Charts/ChartsInvestment'
// import TimeAgo from './TimeAgo'
// import Header2 from './Header2'
import Swal from 'sweetalert2'
import $ from 'jquery'
import '../style.css'
// import { hashKey, headers } from '../../utils/headers'
import AnimatedButton from '../AnimatedButton'
import Sidebar from './Sidebar'
import { LuAlignLeft } from 'react-icons/lu'

const Data2 = (props) => {
  // const {
  //   capitalizeWords,
  //   isAuthExpired,
  //   isOffcanvasActive,
  //   handleMenuToggle,
  //   token,
  //   newNotify,
  //   getNotifyCounts,
  //   notifications,
  //   fetchNotifys,
  // } = props

  // const { objectToFormData, isAdmins, setIsAdmins } = props
  // const [updateTitle, setUpdateTitle] = useState('')
  // const [updateID, setUpdateID] = useState('')
  // const [wallets, setWallets] = useState()
  const [packageN, setPackage] = useState('')
  // const [username, setUsername] = useState()
  // const [accountNo, setAccountNo] = useState()
  const [network, setNetwork] = useState()
  const [phone, setPhone] = useState()
  const [pinSet, setPinSet] = useState(false)
  const [amount, setAmount] = useState()
  const [pin, setPin] = useState()

  const navigate = useNavigate()

  useEffect(() => {
    let record = localStorage.getItem('userDataDani')
    if (record == undefined || record == null) {
      navigate('/login')
    }
    // let records = JSON.parse(record)
    // setUsername(records.name)
    // setAccountNo(records.virtual_acct)
    let isPin = localStorage.getItem('create_pin')
    if (isPin == 1) {
      setPinSet(true)
    }
  })

  useEffect(() => {
    document.title = 'Dashboard | Bill Paymemt'
  }, [])

  // let overNet = ''
  // var tosin = localStorage.getItem('token')

  useEffect(() => {
    let net = sessionStorage.getItem('selectedNetwork')
    let amo = sessionStorage.getItem('airtimeAmount')
    let ph = sessionStorage.getItem('airtimePhoneNumber')
    let pg = sessionStorage.getItem('packageName')
    // let ds = sessionStorage.getItem('dataSelected')
    setPackage(pg)
    setAmount(amo)
    setPhone(ph)
    setNetwork(net)
  })

  // const openLink = (page) => {
  //   navigate(`/${page}/`)
  // }

  // const networkPicked = () => {
  //   let selectedNetwork = $('#network').val()
  //   console.log(selectedNetwork)
  //   sessionStorage.setItem('selectedNetwork', selectedNetwork)
  //   setNetwork(selectedNetwork)
  // }

  const logout = () => {
    localStorage.removeItem('userDataDani')
    // localStorage.removeItem('admin_priv');
    // localStorage.removeItem('admin_roles');
    navigate('/login')
  }

  const vendData = async () => {
    if (pin == '') {
      Swal.fire({
        title: 'error',
        text: 'Please enter 4 digits Transaction Pin',
        icon: 'failed',
      })
      return
    }
    let request_id = Math.random().toString(16).slice(2)

    let planId = sessionStorage.getItem('planId')
    let data = ''
    let url = ''
    if (network == 'globucket') {
      url = API_ROUTES.GLOBUCKETVEND
      data = JSON.stringify({
        network: 'GLO',
        planId: planId,
        type: 'DATA_BUNDLE',
        phonenumber: phone,
        request_id: request_id,
        pin: pin,
      })
    } else {
      let rt = network + '_DATA'
      let bundle = sessionStorage.getItem('packageName')
      url = API_ROUTES.DATAVEND
      data = JSON.stringify({
        network: network,
        bundle: bundle,
        product: rt,
        package: planId,
        amount: amount,
        phonenumber: phone,
        request_id: request_id,
        pin: pin,
      })
    }

    var tosin = localStorage.getItem('token')
    console.log('rt', url)
    console.log('after', url)
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: data,
        headers: { token: tosin, 'Content-Type': 'application/json' },
      })
      const responseJSON = await response.json()

      if (responseJSON.status == '200') {
        Swal.fire({
          title: 'Success!',
          text: 'Your transaction was successful',
          icon: 'success',
        }).then((result) => {
          navigate('/dashboard')
        })
      } else {
        Swal.fire({
          title: 'Failed!',
          text: responseJSON.message,
          icon: 'fail',
        })
      }
    } catch (error) {
      console.log('Error getting data: ', error)
      // setWallets(0);
    }
  }

  // SIDEBAR
  const [openSideBar, setOpenSideBar] = useState(true)

  const sidebar = document.querySelector('.dashboard-sidebar')
  const mobile = document.querySelector('.mobile-sidebar')

  function hideSide() {
    sidebar.classList.toggle('hide')
    setOpenSideBar((prev) => !prev)
  }
  function showSide() {
    mobile.classList.toggle('hide')
  }

  const getContainerStyle = () => {
    const baseStyle = {
      transition: '.5s ease',
    }

    if (window.innerWidth >= 1024) {
      baseStyle.marginLeft = openSideBar ? '16%' : '0px'
    } else {
      baseStyle.marginLeft = '0px'
    }

    return baseStyle
  }

  return (
    <>
      <Sidebar logout={logout} pinSet={pinSet} type={'dashboard'} />
      <div
        className="one-body lg:ml-[16%] min-h-[100vh] max-h-[100vh] overflow-auto relative"
        style={getContainerStyle()}
      >
        <AnimatedButton className="w-min absolute top-[5%] left-[5%]">
          <LuAlignLeft
            onClick={hideSide}
            size={45}
            className="text-[#2D66A8] cursor-pointer"
            id="hide-button"
          />
        </AnimatedButton>{' '}
        <LuAlignLeft
          onClick={showSide}
          size={30}
          className="text-[#2D66A8] cursor-pointer fixed top-[4%] left-[5%]"
          id="show-button"
        />
        <div className="main">
          <div className="main-heading red">DATA</div>
          <div className="purchase-option">
            <AnimatedButton>
              <button
                onClick={() => navigate('/dashboard')}
                className="hover:text-[#FF0000]"
              >
                Dashboard
              </button>
            </AnimatedButton>
            <AnimatedButton>
              <button
                onClick={() => navigate('/airtime')}
                className="buy-airtime hover:text-[#FF0000]"
              >
                Buy Airtime
              </button>
            </AnimatedButton>
          </div>
          <div className="purchase-dashboard">
            <AnimatedButton onClick={() => vendData()} className="pay-button">
              Pay
            </AnimatedButton>

            <div className="airtime-back-button">
              <Link className="" to="/data">
                <img src="../assets/airtime-back.png" alt="" srcset="" />
              </Link>
            </div>
            {/* </a> */}
            <div className="purchase-title">Confirm</div>
            <div className="airtime-form">
              <p>Amount:</p>
              <p className="bold">N{amount}</p>
              <div className="airtime-summary">
                <div className="summary-text">
                  <p>Phone Number:</p>
                  <p>{phone}</p>
                </div>
                <div className="summary-text">
                  <p>Transaction Fee:</p>
                  <p>N0.00</p>
                </div>
                <div className="summary-text">
                  <p>Package:</p>
                  <p>{packageN}</p>
                </div>
                <div className="summary-text">
                  <p>Description:</p>
                  <p>{network} data</p>
                </div>
              </div>
              <div className="summary-text">
                <p>Network:</p>
                <div className="network-logo">
                  <img src={'assets/' + network + '.png'} alt="" />
                  {/* <p className="red">{network} AIRTIME</p> */}
                </div>
              </div>
              <div className="summary-text">
                <p>Transaction PIN:</p>
                <div className="network-logo">
                  <input
                    type="password"
                    id="pin"
                    onInput={(td) => setPin(td.target.value)}
                    name="pin"
                    maxLength={4}
                    placeholder="Enter your Pin"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="hidden lg:block fixed bottom-[15vh] right-0 z-[0]">
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img
                  src="../assets/9mobile 1.png"
                  alt=""
                  className="n-mobile"
                />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/Airtel 3.png" alt="" className="airtel" />
              </AnimatedButton>
            </div>
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/glo.png" alt="" className="glo" />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/MTN 2.png" alt="" className="mtn" />
              </AnimatedButton>
            </div>
          </div>
        </div>
        {/* <div className="background">
        <img src="assets/vending-background.png" alt="" />
      </div> */}
      </div>
    </>
  )
}

export default Data2
