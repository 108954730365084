import React, { useEffect, useState } from "react";
// import Menus from './Menus'
// import Footers from './Footers'
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
// import { format } from 'date-fns'
// import Charts2 from './Charts/Charts2'
// import MenuTop from './MenuTop'
// import axios from 'axios'
// import ChartsInvestment from './Charts/ChartsInvestment'
// import TimeAgo from './TimeAgo'
// import Header2 from './Header2'
import Swal from "sweetalert2";
import $ from "jquery";
import "../style1.css";
// import { hashKey, headers } from '../../utils/headers'
import AnimatedButton from "../AnimatedButton";
import Sidebar from "./Sidebar";
import { LuAlignLeft } from "react-icons/lu";

const Data = (props) => {
  // const {
  //   capitalizeWords,
  //   isAuthExpired,
  //   isOffcanvasActive,
  //   handleMenuToggle,
  //   token,
  //   newNotify,
  //   getNotifyCounts,
  //   notifications,
  //   fetchNotifys,
  // } = props

  // const { objectToFormData, isAdmins, setIsAdmins } = props
  // const [updateTitle, setUpdateTitle] = useState('')
  // const [updateID, setUpdateID] = useState('')
  // const [wallets, setWallets] = useState()
  // const [transactions, setTransactions] = useState([])
  // const [username, setUsername] = useState()
  // const [accountNo, setAccountNo] = useState()
  const [network, setNetwork] = useState("");
  const [phone, setPhone] = useState("");
  const [pinSet, setPinSet] = useState(false);
  // const [amount, setAmount] = useState('')
  // const [globucket, setGlobucket] = useState(false)
  const [databundle, setDatabundle] = useState([]);
  const [dataSelected, setDataSelected] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let record = localStorage.getItem("userDataDani");
    if (record == undefined || record == null) {
      navigate("/login");
    }
    // let records = JSON.parse(record)
    // setUsername(records.name)
    // setAccountNo(records.virtual_acct)
    let isPin = localStorage.getItem("create_pin");
    if (isPin == 1) {
      setPinSet(true);
    }
  });

  useEffect(() => {
    document.title = "Data | Bill Paymemt";
  }, []);

  // let overNet = ''

  // const networkPicked = () => {
  //   let selectedNetwork = $('#network').val()
  //   console.log(selectedNetwork)
  //   sessionStorage.setItem('selectedNetwork', selectedNetwork)
  //   setNetwork(selectedNetwork)
  // }

  const logout = () => {
    localStorage.removeItem("userDataDani");
    // localStorage.removeItem('admin_priv');
    // localStorage.removeItem('admin_roles');
    navigate("/login");
  };

  const dataPicked = () => {
    let selectedData = $("#package").val();
    console.log(selectedData);
    sessionStorage.setItem("planId", selectedData);
    sessionStorage.setItem("selectedData", selectedData);
    const newData = databundle.filter(function (item) {
      // Applying filter for the inserted text in search bar
      const itemData = item.planId ? item.planId : "";
      // const textData = text.toUpperCase();
      return itemData.indexOf(selectedData) > -1;
    });
    console.log(newData);
    let lada = newData[0].package.split(" ");
    setDataSelected(lada);
    sessionStorage.setItem("packageName", newData[0].package);
    sessionStorage.setItem("airtimeAmount", newData[0].price.trim());
    $("#dataAmount").val(newData[0].price.trim());
  };

  const fetchBundle = (net) => {
    setNetwork(net);
    sessionStorage.setItem("selectedNetwork", net);
    let url = "";
    let data = "";
    if (net == "globucket") {
      url = API_ROUTES.GLOBUCKETFETCH;
      data = JSON.stringify({});
    } else {
      url = API_ROUTES.DATAFETCH;
      data = JSON.stringify({
        network: net,
      });
    }

    const lookup = async () => {
      console.log("after", url);
      var tosin = localStorage.getItem("token");
      try {
        const response = await fetch(url, {
          method: "POST",
          body: data,
          headers: { token: tosin, "Content-Type": "application/json" },
        });
        const responseJSON = await response.json();

        if (responseJSON.status == 200) {
          if (net == "globucket") {
            setDatabundle(responseJSON.data);
          } else {
            setDatabundle(responseJSON.data);
          }
        } else {
          logout();
        }
      } catch (error) {
        console.log("Error getting data: ", error);
        // setWallets(0);
      }
    };
    lookup();
  };

  const moveNext = () => {
    console.log(dataSelected);
    if (network == "") {
      Swal.fire({
        title: "error",
        text: "Please select network!",
        icon: "failed",
      });
      return;
    }
    if (dataSelected == "") {
      Swal.fire({
        title: "error",
        text: "Please select a bundle!",
        icon: "failed",
      });
      return;
    }

    if (phone == "") {
      Swal.fire({
        title: "error",
        text: "Please enter phone number!",
        icon: "failed",
      });
      return;
    }

    sessionStorage.setItem("dataSelected", dataSelected);
    sessionStorage.setItem("airtimePhoneNumber", phone);
    navigate("/datasummary");
  };

  const { myNetwork } = location.state || {};

  const [selectValue, setSelectValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (myNetwork || myNetwork == "GloBucket") {
      setIsDisabled(true);
      setSelectValue("globucket");
      fetchBundle("globucket");
    }
  });

  // SIDEBAR
  const [openSideBar, setOpenSideBar] = useState(true);

  const sidebar = document.querySelector(".dashboard-sidebar");
  const mobile = document.querySelector(".mobile-sidebar");

  function hideSide() {
    sidebar.classList.toggle("hide");
    setOpenSideBar((prev) => !prev);
  }
  function showSide() {
    mobile.classList.toggle("hide");
  }

  const getContainerStyle = () => {
    const baseStyle = {
      transition: ".5s ease",
    };

    if (window.innerWidth >= 1024) {
      baseStyle.marginLeft = openSideBar ? "16%" : "0px";
    } else {
      baseStyle.marginLeft = "0px";
    }

    return baseStyle;
  };

  return (
    <>
      <Sidebar logout={logout} pinSet={pinSet} type={"dashboard"} />
      <div
        className="one-body lg:ml-[16%] min-h-[100vh] max-h-[100vh] overflow-auto relative"
        style={getContainerStyle()}
      >
        <AnimatedButton className="w-min absolute top-[5%] left-[5%]">
          <LuAlignLeft
            onClick={hideSide}
            size={45}
            className="text-[#2D66A8] cursor-pointer"
            id="hide-button"
          />
        </AnimatedButton>{" "}
        <LuAlignLeft
          onClick={showSide}
          size={30}
          className="text-[#2D66A8] cursor-pointer fixed top-[4%] left-[5%]"
          id="show-button"
        />
        <div className="main">
          <div className={`main-heading ${myNetwork ? "text-[green]" : "red"}`}>
            {myNetwork ? myNetwork : "Data Purchase"}
          </div>
          <div className="purchase-option">
            <AnimatedButton>
              <button
                onClick={() => navigate("/dashboard")}
                className="hover:text-[#FF0000]"
              >
                Dashboard
              </button>
            </AnimatedButton>
            <AnimatedButton>
              <button
                onClick={() => navigate("/airtime")}
                className="buy-airtime hover:text-[#FF0000]"
              >
                Buy Airtime
              </button>
            </AnimatedButton>
          </div>
          <div className="purchase-dashboard">
            <AnimatedButton onClick={() => moveNext()} className="next-button">
              Next
            </AnimatedButton>
            {/* <Link className="" to="/dashboard">
            <div className="wallet-back-button">
              <img src="../assets/airtime-back.png" alt="" srcset="" />
            </div>
          </Link>
          <div className="purchase-title">Data</div>
          <br /> */}
            <form action="" className="airtime-form">
              <label htmlFor="service-provider">Service Provider</label>
              <select
                className="cursor-pointer"
                name="network"
                id="service-provider"
                disabled={isDisabled}
                value={selectValue}
                onChange={(rt) => {
                  setSelectValue(rt.target.value);
                  fetchBundle(rt.target.value);
                }}
              >
                <option value="">Select Network</option>
                <option value="mtn">MTN-NG</option>
                <option value="glo">Glo</option>
                <option value="airtel">Airtel</option>
                <option value="9mobile">9mobile</option>
                <option value="globucket">Glo Bucket</option>
              </select>
              <br />
              <br />
              <label htmlFor="package">Package</label>
              <select name="package" id="package" onChange={() => dataPicked()}>
                <option value="">Pick Bundle</option>
                {databundle &&
                  databundle.map((dt) => (
                    <option value={dt.planId}>{dt.package}</option>
                  ))}
              </select>
              <br />
              <br />
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                name=""
                id="phone"
                onInput={(yu) => setPhone(yu.target.value)}
                placeholder="0701***545"
              />
              <br />
              <br />
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                name=""
                id="dataAmount"
                min="0"
                step="0.5"
                placeholder="Amount"
                disabled
              />
              <br />
              <br />
            </form>
          </div>
          <div className="hidden lg:block fixed bottom-[15vh] right-0 z-[0]">
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img
                  src="../assets/9mobile 1.png"
                  alt=""
                  className="n-mobile"
                />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/Airtel 3.png" alt="" className="airtel" />
              </AnimatedButton>
            </div>
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/glo.png" alt="" className="glo" />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/MTN 2.png" alt="" className="mtn" />
              </AnimatedButton>
            </div>
          </div>
        </div>
        {/* <div className="background">
        <img src="assets/vending-background.png" alt="" />
      </div> */}
      </div>
    </>
  );
};

export default Data;
