import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../utils/constants";
import "./style.css";
import signUp from "./assets/sign-in.svg";
// import AnimatedButton from "../Components/AnimatedButton";
import TopBanner from "./TopBanner";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

export default function Login(props) {
  useEffect(() => {
    document.title = "Login | noveLT";
  }, []);

  const { objectToFormData, isAdmins, setIsAdmins } = props;
  // const rememberLogin = localStorage.getItem("rememberLoginFs");
  // const rememberMe = rememberLogin ? true : false;
  // const userInput = localStorage.getItem("userInputFs");
  // const inputObject = JSON.parse(userInput);
  // const isEmail = inputObject ? atob(inputObject.email) : "";
  // const isPassword = inputObject ? atob(inputObject.password) : "";

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors, isSubmitting, isValid },
  // } = useForm({
  //   mode: "onChange",
  //   defaultValues: {
  //     email: rememberMe ? isEmail : "",
  //     password: rememberMe ? isPassword : "",
  //     remember: rememberMe,
  //   },
  // });
  // const {
  //   register: register1,
  //   handleSubmit: handleSubmit1,
  //   reset: reset1,
  //   formState: {
  //     errors: errors1,
  //     isSubmitting: isSubmitting1,
  //     isValid: isValid1,
  //   },
  // } = useForm({
  //   mode: "onChange",
  // });

  // const [isChecked, setIsChecked] = useState(rememberMe);
  const [password, setPassword] = useState("");
  // const [name, setName] = useState("");
  // const [passwordr, setPasswordr] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [emailr, setEmailr] = useState("");
  // const [phone, setPhone] = useState("");
  // const [confirm, setConfirm] = useState("");
  const navigate = useNavigate();

  // const userData = localStorage.getItem("userDataDani");
  // const userObj = JSON.parse(userData);
  // // const userObj = userData;
  // const isAuthenticated = {
  //   isLoggedIn: userObj !== null ? userObj.isLoggedIn : false,
  // };

  const setLocalStorageAndNavigate = (responseDetails, data) => {
    const userData = {};
    userData.isLoggedIn = true;
    userData.responseDetails = responseDetails;
    localStorage.setItem("token", responseDetails._token);

    // const userInput = {};
    // userInput.email = btoa(data.email);
    // userInput.password = btoa(data.password);

    // if(isChecked){
    //     localStorage.setItem('userInputFs', JSON.stringify(userInput))
    // }else{
    //     localStorage.removeItem('userInputFs');
    // }
    // localStorage.setItem('rememberLoginFs', data.remember);

    navigate("/verify");
  };

  const loginAuth = async (data) => {
    // navigate("/dashboard");
    console.log(email);
    if (email == "" || password == "") {
      Swal.fire({
        icon: "error",
        html: "Kindly fill all fields correctly",
        title: "Error",
      });
      return;
    }
    data = { email: email, password: password };
    try {
      const response = await fetch(API_ROUTES.SIGN_IN, {
        method: "POST",
        body: objectToFormData(data),
      });
      const responseJSON = await response.json();
      const message = responseJSON.message;

      if (responseJSON.status.toString() === "200") {
        const responseDetails = responseJSON.data;

        setLocalStorageAndNavigate(responseDetails, data);
        setIsAdmins(responseJSON.data.roles);
        localStorage.setItem("admin_roles", responseJSON.data.roles);
      } else {
        Swal.fire({
          title: "<font className='error_title'>Error</font>",
          html: `${message}`,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        html: error,
        title: "Error",
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="one-body h-[100dvh] flex flex-col">
      <TopBanner />
      <div
        className="flex items-center w-full justify-evenly flex-1
      "
      >
        <div className="auth-main">
          <div className="auth-body">
            <div className="sign-in">
              <p className="sign-in-main-text">Sign In</p>
              <p className="sign-in-sub-text">
                To sign in, please type in the email address linked to your
                account.
              </p>
            </div>
            <div action="" className="sign-in-form">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                name=""
                id="email"
                onInput={(text) => setEmail(text.target.value)}
                placeholder="example@gmail.com"
                className="my-[5px]"
              />
              <br />
              <div className="password">
                <label htmlFor="password">Password</label>
                <div className="phone-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    name=""
                    value={password}
                    onChange={(text) => setPassword(text.target.value)}
                    id="password"
                    placeholder="*********"
                  />
                  <div
                    className="cursor-pointer password-image "
                    onClick={() => togglePasswordVisibility()}
                  >
                    {showPassword ? (
                      <FaRegEyeSlash className="text-black size-[18px]" />
                    ) : (
                      <FaRegEye className="text-black size-[18px]" />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <p className="forgot">
                Forgot Password?{" "}
                <Link className="bold link" to="/resetpassword">
                  {" "}
                  Reset it{" "}
                </Link>
              </p>
              <br />
              <div
                className="sign-inbutton cursor-pointer ml-auto"
                onClick={loginAuth}
              >
                Sign in
              </div>
            </div>
            <p className="create-account">
              If you don't have an account, click{" "}
              <Link className="bold link" to="/register">
                here
              </Link>{" "}
              and create an account in a few minutes.
            </p>
          </div>
        </div>
        <div className="hidden lg:block size-[400px]">
          <img src={signUp} alt="" />
        </div>
      </div>
    </div>
  );
}
