import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../utils/constants";
import "./style.css";
import signUp from "./assets/sign-in.svg";
// import AnimatedButton from "../Components/AnimatedButton";
import TopBanner from "./TopBanner";
import { motion } from "framer-motion";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

export default function Verify(props) {
  useEffect(() => {
    document.title = "2FA | noveLT";
  }, []);

  const { objectToFormData, isAdmins, setIsAdmins } = props;
  // const rememberLogin = localStorage.getItem("rememberLoginFs");
  // const rememberMe = rememberLogin ? true : false;
  // const userInput = localStorage.getItem("userInputFs");
  // const inputObject = JSON.parse(userInput);
  // const isEmail = inputObject ? atob(inputObject.email) : "";
  // const isPassword = inputObject ? atob(inputObject.password) : "";

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors, isSubmitting, isValid },
  // } = useForm({
  //   mode: "onChange",
  //   defaultValues: {
  //     email: rememberMe ? isEmail : "",
  //     password: rememberMe ? isPassword : "",
  //     remember: rememberMe,
  //   },
  // });
  // const {
  //   register: register1,
  //   handleSubmit: handleSubmit1,
  //   reset: reset1,
  //   formState: {
  //     errors: errors1,
  //     isSubmitting: isSubmitting1,
  //     isValid: isValid1,
  //   },
  // } = useForm({
  //   mode: "onChange",
  // });

  // const [isChecked, setIsChecked] = useState(rememberMe);
  // const [password, setPassword] = useState("");
  // const [name, setName] = useState("");
  // const [passwordr, setPasswordr] = useState("");
  // const [email, setEmail] = useState("");
  const [verify, setVerify] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  // const [emailr, setEmailr] = useState("");
  // const [phone, setPhone] = useState("");
  // const [confirm, setConfirm] = useState("");
  const navigate = useNavigate();

  // const userData = localStorage.getItem("userDataDani");
  // const userObj = JSON.parse(userData);
  // // const userObj = userData;
  // const isAuthenticated = {
  //   isLoggedIn: userObj !== null ? userObj.isLoggedIn : false,
  // };

  const setLocalStorageAndNavigate = (responseDetails, data) => {
    const userData = {};
    userData.isLoggedIn = true;
    userData.responseDetails = responseDetails;
    localStorage.setItem("token", responseDetails.token);

    localStorage.setItem("userDataDani", JSON.stringify(responseDetails.rec));
    localStorage.setItem("create_pin", responseDetails.rec.pin_status);
    // const userInput = {};
    // userInput.email = btoa(data.email);
    // userInput.password = btoa(data.password);

    // if(isChecked){
    //     localStorage.setItem('userInputFs', JSON.stringify(userInput))
    // }else{
    //     localStorage.removeItem('userInputFs');
    // }
    // localStorage.setItem('rememberLoginFs', data.remember);

    navigate("/dashboard");
  };

  const loginAuth = async (data) => {
    // navigate("/dashboard");
    // console.log(email);
    if (verify == "") {
      Swal.fire({
        icon: "error",
        html: "Kindly enter the correct verification number.",
        title: "Error",
      });
      return;
    }
    data = { token: verify };
    var tosin = localStorage.getItem("token");
    try {
      const response = await fetch(API_ROUTES.VERIFY, {
        method: "POST",
        body: objectToFormData(data),
        headers: { token: tosin },
      });
      const responseJSON = await response.json();
      const message = responseJSON.message;

      if (responseJSON.status.toString() === "200") {
        const responseDetails = responseJSON.data;

        setLocalStorageAndNavigate(responseDetails, data);
        setIsAdmins(responseJSON.data.roles);
        localStorage.setItem("admin_roles", responseJSON.data.roles);
      } else if (responseJSON.status.toString() === "004") {
        Swal.fire({
          title: "<font className='error_title'>Error</font>",
          html: `${message}`,
          icon: "error",
        });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        Swal.fire({
          title: "<font className='error_title'>Error</font>",
          html: `${message}`,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        html: error,
        title: "Error",
      });
    }
  };

  // const togglePasswordVisibility = () => {
  //   setShowPassword((prev) => !prev);
  // };

  return (
    <div className="one-body h-[100dvh] flex flex-col">
      <TopBanner />
      <div
        className="flex items-center w-full justify-evenly flex-1
      "
      >
        <div className="auth-main">
          <div className="auth-body">
            <div className="sign-in">
              <p className="sign-in-main-text">Two Factor Authentication</p>
              <p className="sign-in-sub-text">
                To continue, please type in the verification number sent to the
                email address linked to your account.
              </p>
            </div>
            <div action="" className="sign-in-form">
              <br />
              <br />
              <br />
              <br />
              <div className="otp-prompt">Enter Verification Number</div>
              <div className="otp-input">
                <input
                  type="number"
                  maxLength={6}
                  style={{ width: "90%" }}
                  onInput={(e) => setVerify(e.target.value)}
                  className="otp-digit"
                  placeholder="- - - - - -"
                />
              </div>
              <br />

              <br />
              <motion.div
                whileTap={{ scale: 0.9 }}
                className="sign-inbutton cursor-pointer ml-auto"
                onClick={loginAuth}
              >
                Verify
              </motion.div>
            </div>
            <p className="create-account">
              I don't have the verification number, click{" "}
              <Link className="bold link" to="/login">
                here
              </Link>{" "}
              to go back.
            </p>
            <br />
            <br />
          </div>
        </div>
        <div className="hidden lg:block size-[400px]">
          <img src={signUp} alt="" />
        </div>
      </div>
    </div>
  );
}
