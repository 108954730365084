import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../utils/constants";
import "./style.css";
import TopBanner from "./TopBanner";
import forgotPassword from "./assets/forgotPassword.svg";
// import appLogo from '../images/loripay_logo.png';
// import loginBG from '../images/login3.png';

export default function PasswordReset(props) {
  useEffect(() => {
    document.title = "Login | noveLT";
  }, []);

  // const { objectToFormData, isAdmins, setIsAdmins } = props
  // const rememberLogin = localStorage.getItem("rememberLoginFs");
  // const rememberMe = rememberLogin ? true : false;
  // const userInput = localStorage.getItem("userInputFs");
  // const inputObject = JSON.parse(userInput);
  // const isEmail = inputObject ? atob(inputObject.email) : "";
  // const isPassword = inputObject ? atob(inputObject.password) : "";

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors, isSubmitting, isValid },
  // } = useForm({
  //   mode: "onChange",
  //   defaultValues: {
  //     email: rememberMe ? isEmail : "",
  //     password: rememberMe ? isPassword : "",
  //     remember: rememberMe,
  //   },
  // });
  const {
    // register: register1,
    // handleSubmit: handleSubmit1,
    reset: reset1,
    // formState: {
    //   errors: errors1,
    //   isSubmitting: isSubmitting1,
    //   isValid: isValid1,
    // },
  } = useForm({
    mode: "onChange",
  });

  // const [isChecked, setIsChecked] = useState(rememberMe)
  // const [password, setPassword] = useState('')
  // const [name, setName] = useState('')
  // const [passwordr, setPasswordr] = useState('')
  const [email, setEmail] = useState("");
  // const [emailr, setEmailr] = useState('')
  // const [phone, setPhone] = useState('')
  // const [confirm, setConfirm] = useState('')
  // const [showPassword, setShowPassword] = useState(false)
  // const [showLoginForm, setShowLoginForm] = useState(true)
  // const [showReset, setShowReset] = useState(false)
  // const [showCreateAcct, setShowCreateAcct] = useState(false)
  const [isbusy, setIsBusy] = useState(false);
  const navigate = useNavigate();

  // const showFormReset = () => {
  //   setShowLoginForm(false)
  //   setShowReset(true)
  //   setShowCreateAcct(false)
  // }
  // const showFormLogin = () => {
  //   setShowLoginForm(true)
  //   setShowReset(false)
  //   setShowCreateAcct(false)
  // }

  // const createAcct = () => {
  //   setShowLoginForm(false)
  //   setShowReset(false)
  //   setShowCreateAcct(true)
  // }

  // const userData = localStorage.getItem("userDataDani");
  // const userObj = JSON.parse(userData);
  // const userObj = userData;
  // const isAuthenticated = {
  //   isLoggedIn: userObj !== null ? userObj.isLoggedIn : false,
  // };

  // const setLocalStorageAndNavigate = (responseDetails, data) => {
  //   const userData = {};
  //   userData.isLoggedIn = true;
  //   userData.responseDetails = responseDetails;
  //   localStorage.setItem("token", responseDetails._token);

  //   localStorage.setItem(
  //     "userDataDani",
  //     JSON.stringify(responseDetails.record)
  //   );
  //   // const userInput = {};
  //   // userInput.email = btoa(data.email);
  //   // userInput.password = btoa(data.password);

  //   // if(isChecked){
  //   //     localStorage.setItem('userInputFs', JSON.stringify(userInput))
  //   // }else{
  //   //     localStorage.removeItem('userInputFs');
  //   // }
  //   // localStorage.setItem('rememberLoginFs', data.remember);

  //   navigate("/dashboard");
  // };

  const resetPassword = async () => {
    // let data = {'email':email}

    var data = new FormData();
    data.append("email", email);
    if (email == "") {
      Swal.fire({
        icon: "error",
        html: "Please provide a valid email address",
        title: "Error",
      });
      return;
    }
    setIsBusy(true);
    try {
      const response = await fetch(API_ROUTES.RESET_PASSWORD, {
        method: "POST",
        body: data,
      });
      const responseJSON = await response.json();
      const message = responseJSON.message;

      if (responseJSON.status.toString() == "200") {
        // const responseDetails = responseJSON.data
        console.log(message);

        Swal.fire({
          icon: "success",
          html: message + ". New password sent to your email address",
          title: "Successfully",
        });
        reset1();
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        Swal.fire({
          title: "<font className='error_title'>Error</font>",
          html: `${message}`,
          icon: "error",
        });
      }
      setIsBusy(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        html: error,
        title: "Error",
      });
      setIsBusy(false);
    }
  };

  return (
    <div className="one-body h-[100dvh] flex flex-col">
      <TopBanner />
      <div
        className="flex items-center w-full justify-evenly flex-1
      "
      >
        <div className="auth-main">
          <div className="auth-body">
            <div className="sign-in">
              <p className="sign-in-main-text">Reset Password</p>
              <p className="sign-in-sub-text">
                To reset your password, please type in the email address linked
                to your account.
              </p>
            </div>
            <div action="" className="sign-in-form">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                name=""
                id="email"
                onInput={(text) => setEmail(text.target.value)}
                placeholder="example@gmail.com"
              />
              <br />
              <br />
              <div className="password"></div>
              <br />
              <br />
              <p className="forgot">
                Remember Password?{" "}
                <Link className="bold link" to="/login">
                  {" "}
                  Login{" "}
                </Link>
              </p>
              <br />
              <br />
              <button
                className="sign-inbutton"
                disabled={isbusy}
                onClick={resetPassword}
              >
                Reset
              </button>
            </div>
            <p className="create-account">
              If you don't have an account, click
              <Link className="bold link" to="/register">
                {" "}
                Here{" "}
              </Link>
              to create an account in a few minutes.
            </p>
          </div>
        </div>
        <div className="hidden lg:block size-[400px]">
          <img src={forgotPassword} alt="" />
        </div>
      </div>
    </div>
  );
}
