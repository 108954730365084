import React, { useEffect, useState } from 'react'
// import Menus from './Menus'
// import Footers from './Footers'
import { useNavigate } from 'react-router-dom'
import { API_ROUTES } from '../../utils/constants'
// import { format } from 'date-fns'
// import Charts2 from './Charts/Charts2'
// import MenuTop from './MenuTop'
// import axios from 'axios'
// import ChartsInvestment from './Charts/ChartsInvestment'
// import TimeAgo from './TimeAgo'
// import Header2 from './Header2'
import '../style.css'
import '../modal'
import AnimatedButton from '../AnimatedButton'
import Sidebar from './Sidebar'
import DataTable from 'react-data-table-component'
import { IoSearch } from 'react-icons/io5'
import { LuAlignLeft } from 'react-icons/lu'
// import '../table';

// SEARCH COMPONENT
const FilterComponent = ({ filterText, onFilter }) => (
  <div className="absolute top-0 right-0 translate-y-[-100%] flex items-center text-[14px] lg:text-[16] p-2 lg:p-3 w-full lg:max-w-min">
    <input
      id="search"
      type="text"
      className="p-1 lg:p-2 border border-[grey] rounded-l-[4px] outline-none w-full lg:w-auto h-[30px] lg:h-[40px]"
      placeholder="Search..."
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <div className="rounded-r-[4px] bg-[grey] size-[30px] lg:size-[40px] flex items-center justify-center">
      <IoSearch className="size-[20px] lg:size-[25px] text-white" />
    </div>
  </div>
)

const Wallet = () => {
  const navigate = useNavigate()

  // const [updateTitle, setUpdateTitle] = useState('')
  // const [updateID, setUpdateID] = useState('')
  // const [wallets, setWallets] = useState()
  const [walletHistory, setWalletHistory] = useState([])
  // const [accountNo, setAccountNo] = useState()
  // const [modalActive, setModalActive] = useState(false)
  const [pinSet, setPinSet] = useState(false)

  // SEARCH
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

  const filteredItems = walletHistory.filter(
    (item) =>
      (item.type &&
        item.type.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.amount && item.amount.toString().includes(filterText)) ||
      (item.amount && item.balance_after.toString().includes(filterText)) ||
      (item.description &&
        item.description.toLowerCase().includes(filterText.toLowerCase())),
  )

  const handleFilter = (event) => {
    setFilterText(event.target.value)
    setResetPaginationToggle(!resetPaginationToggle)
  }

  useEffect(() => {
    let record = localStorage.getItem('userDataDani')
    if (record == undefined || record == null) {
      console.log('alashe')
      navigate('/login')
    }
    let records = JSON.parse(record)
    let isPin = localStorage.getItem('create_pin')
    if (isPin == 1) {
      setPinSet(true)
    }
  })

  var tosin = localStorage.getItem('token')
  // const getDatas = async () => {
  //   try {
  //     const response = await fetch(API_ROUTES.GET_WALLET, {
  //       method: 'POST',
  //       headers: { token: tosin },
  //     })
  //     const responseJSON = await response.json()

  //     if (responseJSON.status == 200) {
  //       console.log(responseJSON.balance)
  //       const responseData = responseJSON.balance
  //       setWallets(responseData)
  //     } else {
  //       logout()
  //     }
  //   } catch (error) {
  //     console.log('Error getting data: ', error)
  //     setWallets(0)
  //   }
  // }

  useEffect(() => {
    let record = localStorage.getItem('userDataDani')
    if (record == undefined || record == null) {
      console.log('alashe')
      navigate('/login')
    }
    // let records = JSON.parse(record)
    // setAccountNo(records.virtual_acct)
  })

  const getWallet = async () => {
    var raw = JSON.stringify({})
    try {
      const response = await fetch(API_ROUTES.WALLET_HISTORY, {
        method: 'POST',
        body: raw,
        headers: { token: tosin },
      })
      const responseJSON = await response.json()

      if (responseJSON.status == 200) {
        console.log(responseJSON.data)
        const responseData = responseJSON.data
        setWalletHistory(responseData)
      } else {
        logout()
      }
    } catch (error) {
      console.log('Error getting data: ', error)
      // setWallets(0);
    }
  }

  useEffect(() => {
    // getDatas()
    getWallet()
    // fetchNotifys();
  }, [])

  const logout = () => {
    localStorage.removeItem('userDataDani')
    // localStorage.removeItem('admin_priv');
    // localStorage.removeItem('admin_roles');
    navigate('/login')
  }

  const modal = document.querySelector('.modal')

  // function openModal() {
  //   //   modal.style.display = 'block'
  //   setModalActive(true)
  // }
  // function closeModal() {
  //   //   modal.style.display = 'none'
  //   setModalActive(false)
  // }
  // window.onclick = function (e) {
  //   if (e.target === modal) {
  //     // setModalActive(false)
  //   }
  // }

  const columns = [
    {
      name: 'Type',
      sortable: true,
      center: true,
      selector: (row) => `Wallet ${row.type}`,
    },
    {
      name: 'Date',
      sortable: true,
      center: true,
      selector: (row) => row.created_at,
    },
    {
      name: 'Amount',
      sortable: true,
      center: true,
      selector: (row) => `₦${parseFloat(row.amount).toLocaleString()}`,
    },
    {
      name: 'Balance After',
      sortable: true,
      center: true,
      selector: (row) => `₦${parseFloat(row.balance_after).toLocaleString()}`,
    },
    {
      name: 'Description',
      sortable: true,
      center: true,
      selector: (row) => row.description,
    },
  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px',
        justifyContent: 'flex-end',
      },
    },
    headCells: {
      style: {
        fontSize: '17px',
        fontWeight: 800,
      },
    },
    cells: {
      style: {
        fontSize: '16px',
        paddingRight: '10px',
      },
    },
  }

    // SIDEBAR
    const [openSideBar, setOpenSideBar] = useState(true)

    const sidebar = document.querySelector('.dashboard-sidebar')
    const mobile = document.querySelector('.mobile-sidebar')
  
    function hideSide() {
      sidebar.classList.toggle('hide')
      setOpenSideBar((prev) => !prev)
    }
    function showSide() {
      mobile.classList.toggle('hide')
    }
  
    const getContainerStyle = () => {
      const baseStyle = {
        transition: '.5s ease',
      }
  
      if (window.innerWidth >= 1024) {
        baseStyle.marginLeft = openSideBar ? '16%' : '0px'
      } else {
        baseStyle.marginLeft = '0px'
      }
  
      return baseStyle
    }
  
  return (
    <>
      <Sidebar logout={logout} pinSet={pinSet} type={'dashboard'} />
      <div className="wallet-history-body relative" style={getContainerStyle()}>
        <AnimatedButton className="w-min absolute top-[5%] left-[5%]">
          <LuAlignLeft
            onClick={hideSide}
            size={45}
            className="text-[#2D66A8] cursor-pointer"
            id="hide-button"
          />
        </AnimatedButton>{' '}
        <LuAlignLeft
          onClick={showSide}
          size={30}
          className="text-[#2D66A8] cursor-pointer fixed top-[4%] left-[5%]"
          id="show-button"
        />
        <div className="main">
          <div className="wallet-history">
            <div className="wallet-history-head">
              <div className="main-wallet-heading red">Wallet History</div>
            </div>
            <div className="purchase-option">
              <AnimatedButton>
                <button
                  onClick={() => navigate('/dashboard')}
                  className="buy-airtime hover:text-[#FF0000]"
                >
                  Dashboard
                </button>
              </AnimatedButton>
            </div>
            <div className="relative mt-[20px] lg:mt-0">
              <FilterComponent
                filterText={filterText}
                onFilter={handleFilter}
              />
              <DataTable
                columns={columns}
                data={filteredItems}
                customStyles={customStyles}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
              />
            </div>
          </div>
          <div className="hidden lg:block fixed bottom-[15vh] right-0 z-[-1]">
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img
                  src="../assets/9mobile 1.png"
                  alt=""
                  className="n-mobile"
                />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/Airtel 3.png" alt="" className="airtel" />
              </AnimatedButton>
            </div>
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/glo.png" alt="" className="glo" />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/MTN 2.png" alt="" className="mtn" />
              </AnimatedButton>
            </div>
          </div>
        </div>
        {/* <script src="table.js"></script>
            <script src="modal.js"></script> */}
      </div>
    </>
  )
}

export default Wallet
