import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../utils/constants";
import "./style.css";
import Sidebar from "./Dashboards/Sidebar";
import AnimatedButton from "./AnimatedButton";
import { LuAlignLeft } from "react-icons/lu";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

export default function PasswordChange(props) {
  useEffect(() => {
    document.title = "Change password | noveLT";
  }, []);

  useEffect(() => {
    let record = localStorage.getItem("userDataDani");
    if (record == undefined || record == null) {
      navigate("/login");
    } else {
      // let records = JSON.parse(record);
      // setUsername(records.name);
      let isPin = localStorage.getItem("create_pin");
      if (isPin == 1) {
        setPinSet(true);
      }
    }
  });

  const logout = () => {
    localStorage.removeItem("userDataDani");
    navigate("/login");
  };

  const [confirmPassword, setConfirmPassword] = useState("");
  // const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showNPassword, setShowNPassword] = useState(false);
  // const [pinCreated, setPinCreated] = useState(false)
  const [pinSet, setPinSet] = useState(false);
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");

  //   const userData = localStorage.getItem('userDataDani')
  //   const userObj = JSON.parse(userData)

  //   const isAuthenticated = {
  //     isLoggedIn: userObj !== null ? userObj.isLoggedIn : false,
  //   }

  const changePassword = async (data) => {
    try {
      if (oldPassword == "" || password == "") {
        Swal.fire({
          title: "Error",
          text: "Please enter all fields",
          icon: "error",
        });
        return false;
      }
      if (password !== confirmPassword) {
        Swal.fire({
          title: "Error",
          text: "The two passwords must match",
          icon: "error",
        });
        return false;
      }

      var raw = JSON.stringify({
        oldPassword: oldPassword,
        newPassword: password,
        confirmPassword: confirmPassword,
      });

      var tosin = localStorage.getItem("token");

      var myHeaders = new Headers();
      myHeaders.append("token", tosin);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(API_ROUTES.CHANGE_PASSWORD, requestOptions);
      const responseJSON = await response.json();

      if (responseJSON.status.toString() === "200") {
        Swal.fire({
          title: "Successful",
          text: responseJSON.message,
          icon: "success",
        });
      } else {
        console.log(responseJSON);
        Swal.fire({
          title: "Error",
          text:
            responseJSON.message ||
            "An error occurred during the POST request.",
          icon: "error",
        });
      }
    } catch (error) {
      console.log("Error getting data: ", error);
      Swal.fire({
        title: "Error",
        text: error || "Network error!",
        icon: "error",
      });
    }
  };
  // SIDEBAR
  const [openSideBar, setOpenSideBar] = useState(true);

  const sidebar = document.querySelector(".dashboard-sidebar");
  const mobile = document.querySelector(".mobile-sidebar");

  function hideSide() {
    sidebar.classList.toggle("hide");
    setOpenSideBar((prev) => !prev);
  }
  function showSide() {
    mobile.classList.toggle("hide");
  }

  const getContainerStyle = () => {
    const baseStyle = {
      transition: ".5s ease",
    };

    if (window.innerWidth >= 1024) {
      baseStyle.marginLeft = openSideBar ? "16%" : "0px";
    } else {
      baseStyle.marginLeft = "0px";
    }

    return baseStyle;
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
    console.log("show password");
  };

  const toggleCPasswordVisibility = () => {
    setShowCPassword((prev) => !prev);
  };

  const toggleNPasswordVisibility = () => {
    setShowNPassword((prev) => !prev);
  };

  return (
    <>
      <Sidebar logout={logout} pinSet={pinSet} type={"profile"} />

      <div
        className="airtime-vending-body min-h-[100vh] max-h-[100vh] overflow-auto relative"
        style={getContainerStyle()}
      >
        {" "}
        <AnimatedButton className="w-min absolute top-[5%] left-[5%]">
          <LuAlignLeft
            onClick={hideSide}
            size={45}
            className="text-[#2D66A8] cursor-pointer"
            id="hide-button"
          />
        </AnimatedButton>{" "}
        <LuAlignLeft
          onClick={showSide}
          size={30}
          className="text-[#2D66A8] cursor-pointer fixed top-[4%] left-[5%]"
          id="show-button"
        />
        <div className="main h-[100%]">
          <div className="main-heading red mt-[30px] md:mt-[auto]">
            Change your password
          </div>
          <div className="purchase-option">
            <AnimatedButton>
              <button
                onClick={() => navigate("/dashboard")}
                className="buy-airtime hover:text-[#FF0000]"
              >
                Dashboard
              </button>
            </AnimatedButton>
          </div>
          <div className="purchase-dashboard">
            <AnimatedButton className="next-button">
              <button onClick={() => changePassword()}>Submit</button>
            </AnimatedButton>
            <div className="airtime-form">
              <div className="password">
                <label htmlFor="current-password">Current Password</label>
                <div className="phone-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    onChange={(text) => setOldPassword(text.target.value)}
                    id="current-password"
                    placeholder="enter your current password"
                  />
                  <div
                    className="cursor-pointer password-image"
                    onClick={() => {
                      togglePasswordVisibility();
                    }}
                  >
                    {showPassword ? (
                      <FaRegEyeSlash className="text-black size-[18px]" />
                    ) : (
                      <FaRegEye className="text-black size-[18px]" />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="password">
                <label htmlFor="new-password">New Password</label>
                <div className="phone-input">
                  <input
                    type={showNPassword ? "text" : "password"}
                    onChange={(text) => setPassword(text.target.value)}
                    id="new-password"
                    placeholder="enter your new password"
                  />
                  <div
                    className="cursor-pointer password-image"
                    onClick={() => {
                      toggleNPasswordVisibility();
                    }}
                  >
                    {showNPassword ? (
                      <FaRegEyeSlash className="text-black size-[18px]" />
                    ) : (
                      <FaRegEye className="text-black size-[18px]" />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="password">
                <label htmlFor="confirm-password">Confirm Password</label>
                <div className="phone-input">
                  <input
                    type={showCPassword ? "text" : "password"}
                    onChange={(text) => setConfirmPassword(text.target.value)}
                    id="confirm-password"
                    placeholder="enter your password again"
                  />
                  <div
                    className="cursor-pointer password-image"
                    onClick={() => {
                      toggleCPasswordVisibility();
                    }}
                  >
                    {showCPassword ? (
                      <FaRegEyeSlash className="text-black size-[18px]" />
                    ) : (
                      <FaRegEye className="text-black size-[18px]" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block fixed bottom-[15vh] right-0 z-[0]">
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img
                  src="../assets/9mobile 1.png"
                  alt=""
                  className="n-mobile"
                />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/Airtel 3.png" alt="" className="airtel" />
              </AnimatedButton>
            </div>
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/glo.png" alt="" className="glo" />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/MTN 2.png" alt="" className="mtn" />
              </AnimatedButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
