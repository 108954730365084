import React, { useEffect, useState, useRef } from "react";
// import { useForm } from "react-hook-form";
// import Swal from "sweetalert2";
import "./style1.css";
import { useNavigate } from "react-router-dom";
// import { API_ROUTES } from "../utils/constants";
import { motion } from "framer-motion";
import { AiOutlineApi } from "react-icons/ai";
import { GiHamburgerMenu, GiSmartphone } from "react-icons/gi";
import { RiCustomerService2Fill } from "react-icons/ri";
import AnimatedButton from "./AnimatedButton";
import Logo from "./Logo";
import { IoClose } from "react-icons/io5";

export default function Home(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let record = localStorage.getItem("userDataDani");
    if (record === undefined || record == null) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  const login = () => {
    let record = localStorage.getItem("userDataDani");
    if (record === undefined || record == null) {
      navigate("/login");
    } else {
      setIsLoggedIn(true);
      navigate("/dashboard");
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  const logout = () => {
    localStorage.removeItem("userDataDani");
    handleReload();
  };

  const aboutRef = useRef(null);
  const servicesRef = useRef(null);

  const scrollToSection = (section) => {
    const targetPosition = section.current.offsetTop - 0;
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  return (
    <div className="landing">
      <div
        className="landing-2"
        style={{ backgroundImage: 'url("../assets/landing-2-background.png")' }}
      >
        <div style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
          <nav>
            <div className="nav-logo">
              {/* <img src="../assets/dashboard-logo.png" alt="" /> */}
              <Logo />
            </div>
            <div className="nav-right">
              <div className="nav-links hidden md:flex">
                <div className="nav-link">Home</div>
                <div
                  className="nav-link"
                  onClick={() => {
                    scrollToSection(aboutRef);
                  }}
                >
                  About Us
                </div>
                <div
                  className="nav-link"
                  onClick={() => {
                    scrollToSection(servicesRef);
                  }}
                >
                  Services
                </div>
                {isLoggedIn && (
                  <div
                    className="nav-link"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    Dashboard
                  </div>
                )}
                {isLoggedIn && (
                  <div className="nav-link" onClick={() => logout()}>
                    Logout
                  </div>
                )}
              </div>
              <div className="block md:hidden">
                <motion.div whileTap={{ scale: 0.7 }}>
                  {showMobileMenu ? (
                    <IoClose
                      className="text-white size-8 cursor-pointer"
                      onClick={() => {
                        handleMobileMenu();
                      }}
                    />
                  ) : (
                    <GiHamburgerMenu
                      className="text-white size-8 cursor-pointer"
                      onClick={() => {
                        handleMobileMenu();
                      }}
                    />
                  )}
                </motion.div>
              </div>
            </div>
          </nav>
          <div
            className="mobile-nav w-screen overflow-hidden flex flex-col items-center gap-[20px] md:hidden text-white text-xl font-bold"
            style={{
              backdropFilter: "blur(5px)",
              padding: showMobileMenu ? "20px" : "0px",
              height: showMobileMenu ? "auto" : "0px",
              transition: "0.3s ease",
            }}
          >
            <div
              className="nav-link"
              onClick={() => {
                handleMobileMenu();
              }}
            >
              Home
            </div>
            <div
              className="nav-link"
              onClick={() => {
                scrollToSection(aboutRef);
              }}
            >
              About Us
            </div>
            <div
              className="nav-link"
              onClick={() => {
                scrollToSection(servicesRef);
              }}
            >
              Services
            </div>
            {isLoggedIn && (
              <div
                className="nav-link"
                onClick={() => {
                  handleMobileMenu();
                  navigate("/dashboard");
                }}
              >
                Dashboard
              </div>
            )}
            {isLoggedIn && (
              <div
                className="nav-link"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </div>
            )}
          </div>
        </div>
        <section className="section-one">
          <div className="section-one-left">
            <div className="networks">
              <div className="flex">
                <AnimatedButton className="cursor-pointer">
                  {" "}
                  <img
                    src="../assets/9mobile 1.png"
                    alt=""
                    className="n-mobile"
                  />
                </AnimatedButton>
                <AnimatedButton className="">
                  {" "}
                  <img src="../assets/Airtel 3.png" alt="" className="airtel" />
                </AnimatedButton>
              </div>
              <div className="flex">
                <AnimatedButton className="">
                  {" "}
                  <img src="../assets/glo.png" alt="" className="glo" />
                </AnimatedButton>
                <AnimatedButton className="">
                  {" "}
                  <img src="../assets/MTN 2.png" alt="" className="mtn" />
                </AnimatedButton>
              </div>
            </div>
            <div className="section-one-heading gilroy-extra-bold text-[34px] md:text-[50px]">
              EXPERIENCE UNBEATABLE <br />
              PRICES ON INSTANT AIRTIME <br /> & DATA PLANS!
            </div>
            <div className="section-one-p">
              Treat yourself to affordable and reliable airtime & data bundles
              with <br />
              instant delivery across all networks
            </div>
            <div className="section-one-buttons">
              {!isLoggedIn && (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    navigate("/register");
                  }}
                  className="section-one-button get-started cursor-pointer"
                >
                  Get Started
                </motion.div>
              )}
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => login()}
                className="section-one-button sign-in-button cursor-pointer"
                style={{ color: "black" }}
              >
                {!isLoggedIn ? "Sign-in" : "Dashboard"}
              </motion.div>
            </div>
          </div>
          <div className="section-one-right">
            <img src="../assets/section-one.png" alt="" />
          </div>
        </section>
        <hr />
        <section className="section-two about" ref={aboutRef}>
          <div className="section-two-text">
            <div className="section-two-heading">
              About <span className="pink">Us</span>
            </div>
            <p className="paragraph">
              We provide instant airtime recharge and Data top-up at the
              cheapest rate
            </p>
          </div>
          <div className="section-two-flex">
            <div className="section-two-left">
              <div className="section-two-card">
                <div className="section-two-icon">
                  <img src="../assets/section-two1.png" alt="" />
                </div>
                <div className="section-two-card-heading">
                  Our <span className="red">Mission</span>
                </div>
                <div className="section-two-card-p paragraph">
                  Empower Nigeria with an efficient VTU platform for affordable,
                  instant, and secure data bundles and airtime recharges,
                  ensuring worry-free connectivity{" "}
                </div>
              </div>
              <div className="section-two-card">
                <div className="section-two-icon">
                  <img src="../assets/section-two2.png" alt="" />
                </div>
                <div className="section-two-card-heading">
                  Our <span className="red">Vision</span>
                </div>
                <div className="section-two-card-p paragraph">
                  Be NIgeria's top choice for reliable and affordable VTU
                  services, reshaping how people access and utilize data, while
                  providing financial benefits through 2% cashback on every
                  transaction.
                </div>
              </div>
            </div>
            <div className="section-two-right">
              <img src="../assets/section-two.png" alt="" />
            </div>
          </div>
        </section>
        <section className="section-three" ref={servicesRef}>
          <div className="section-three-left">
            <img src="../assets/section-three.png" alt="" />
          </div>
          <div className="section-three-right">
            <div className="section-four-heading">
              Why you should <br />
              <span className="blue">Choose</span> and{" "}
              <span className="pink">Trust Us</span>
            </div>

            <div className="section-two-card-p paragraph">
              Ensuring reliability, efficiency, and security, our airtime and
              data business prioritizes customer-centric services. Trust in
              seamless transactions with a focus on your satisfaction
            </div>
            <div className="services">
              <div className="service">
                <img src="../assets/tick.png" alt="" />
                <p>Reliability</p>
              </div>
              <div className="service">
                <img src="../assets/tick.png" alt="" />
                <p>Timeliness</p>
              </div>
              <div className="service">
                <img src="../assets/tick.png" alt="" />
                <p>Efficiency</p>
              </div>
              <div className="service">
                <img src="../assets/tick.png" alt="" />
                <p>Security</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-four" ref={servicesRef}>
          <div className="section-four-text">
            <div className="section-three-heading">
              Our <span className="pink">Services</span>
            </div>
            <div className="section-four-p paragraph">
              Reliable and secure transactions backed by years of industry
              experience.
              <br /> 24/7 customer support ensures you’re never left without
              help when you need it.
            </div>
          </div>
          <div className="section-four-cards">
            <div className="section-four-card">
              <div className="section-four-icon">
                <AiOutlineApi className="text-white size-[50px]" />
              </div>
              <div className="section-four-card-text">
                {/* <div className="section-four-card-heading heading  text-black group-hover:text-white"> */}
                <div className="section-four-card-heading heading">
                  Airtime & Data API Integrations{" "}
                </div>
                <div className="section-four-text-p paragraph ">
                  Seamless Airtime & Data API integrations for efficient and
                  reliable service delivery.
                </div>
              </div>
            </div>
            <div className="section-four-card">
              <div className="section-four-icon">
                <GiSmartphone className="size-[50px] text-black" />
              </div>
              <div className="section-four-card-text">
                <div className="section-four-card-heading heading">
                  Airtime and <span className="blue">Data</span>
                </div>
                <div className="section-four-text-p paragraph">
                  Effortless and secure airtime and data vending solutions
                  tailored for your convenience.
                </div>
              </div>
            </div>
            <div className="section-four-card">
              <div className="section-four-icon">
                <RiCustomerService2Fill className="size-[50px] text-black" />
              </div>
              <div className="section-four-card-text">
                <div className="section-four-card-heading heading">
                  Free <span className="blue">Consultations</span>
                </div>
                <div className="section-four-text-p paragraph">
                  Take advantage of our free consultations to find the best
                  solutions for your needs.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-five">
          <div className="section-four-heading">
            See What People Are <br />
            Saying <span className="pink">About Us</span>
          </div>

          <div className="testimonial-row">
            <div className="testimonial-col testimonial-col1">
              <div
                className="tim testimonial"
                style={{ backgroundColor: "#2D66A8" }}
              >
                <div className="testimonial-pic">
                  <img
                    src="../assets/user.png"
                    alt="user"
                    style={{ width: "60px", borderRadius: "200" }}
                  />
                </div>

                <div
                  className="testimonial-content"
                  style={{ color: "white", padding: "20px" }}
                >
                  Life as a busy professional means needing phone and data
                  top-ups at odd hours. This app has been a lifesaver! It's
                  super convenient, letting me recharge with just a few clicks
                  anytime, anywhere. Fast, reliable, and a huge time-saver.
                  Highly recommended!
                  <div className="testimonial-card-name mt-10 ">
                    <div className="testimonial-card-name-left">
                      <div className="name" style={{ color: "white" }}>
                        Oluwaseun Adeola
                      </div>
                      <div className="position" style={{ color: "white" }}>
                        Regional Mobility Manager
                      </div>
                    </div>
                    <div className="testimonial-card-name-right">
                      <img src="../assets/stars.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="moreen testimonial">
                <div className="testimonial-pic">
                  <img
                    src="../assets/user.png"
                    alt="user"
                    style={{ width: "60px", borderRadius: "200" }}
                  />
                </div>
                <div className="testimonial-content">
                  This app ensures seamless communication within our district.
                  Quick airtime and data top-ups keep our teams agile and
                  responsive.
                </div>
                <div className="testimonial-card-name">
                  <div className="testimonial-card-name-left">
                    <div className="name">Chinedu Okafor</div>
                    <div className="position">District Directives Producer</div>
                  </div>
                  <div className="testimonial-card-name-right">
                    <img src="../assets/stars.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-col testimonial-col2">
              <div className="vincent testimonial">
                <div className="testimonial-pic">
                  <img
                    src="../assets/user.png"
                    alt="user"
                    style={{ width: "60px", borderRadius: "200" }}
                  />
                </div>
                <div className="testimonial-content">
                  With this app, allocating mobile allowances and data stipends
                  to employees is a breeze. It streamlines HR processes and
                  fosters employee satisfaction.
                </div>
                <div className="testimonial-card-name">
                  <div className="testimonial-card-name-left">
                    <div className="name">Aisha Mohammed</div>
                    <div className="position">Human Accounts Supervisor</div>
                  </div>
                  <div className="testimonial-card-name-right">
                    <img src="../assets/stars.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="john testimonial">
                <div className="testimonial-pic">
                  <img
                    src="../assets/user.png"
                    alt="user"
                    style={{ width: "60px", borderRadius: "200" }}
                  />
                </div>
                <div className="testimonial-content">
                  This app offers a modern solution for managing
                  telecommunications needs. Quick access to airtime and data
                  empowers our team to stay ahead in today's dynamic business
                  landscape.
                </div>
                <div className="testimonial-card-name">
                  <div className="testimonial-card-name-left">
                    <div className="name">Ebiere Tamuno</div>
                    <div className="position">Forward Paradigm Manager</div>
                  </div>
                  <div className="testimonial-card-name-right">
                    <img src="../assets/stars.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="footer-col">
            <Logo />
            <div className="small-text">
              Treat yourself to affordable and reliable air time & data bundles
              with instant delivery across all networks.
            </div>
          </div>
          <div className="footer-col">
            <h6>Quick Links</h6>
            <div className="footer-col2-bottom">
              <div
                className="small-text"
                onClick={() => {
                  scrollToSection(aboutRef);
                }}
              >
                About Us
              </div>
              <div
                className="small-text"
                onClick={() => {
                  scrollToSection(servicesRef);
                }}
              >
                Service
              </div>
              <div className="small-text">Pricing</div>
              <div className="small-text" onClick={() => navigate("/contact")}>
                Contact Us
              </div>
            </div>
          </div>
          <div className="footer-col">
            <h6>Contact Us</h6>
            <div className="footer-col3-bottom">
              <div className="footer-col3-row">
                <img src="../assets/mail-icon.png" alt="" />
                <div className="small-text">hello@noveLT.net</div>
              </div>
              <div className="footer-col3-row">
                <img src="../assets/location-icon.png" alt="" />
                <div className="small-text">
                  Riverside Building, County Hall, GRA, Ikeja, Lagos, Nigeria.
                </div>
              </div>
              <div className="footer-col3-row">
                <img src="../assets/phone-icon.png" alt="" />
                <div className="small-text">+234 8121234560</div>
              </div>
            </div>
          </div>
          <div className="footer-col">
            <h6>Follow Us</h6>
            <div className="footer-socials">
              <img src="../assets/footer-twitter.png" alt="" />
              <img src="../assets/footer-Ig.png" alt="" />
              <img src="../assets/footer-FB.png" alt="" />
              <img src="../assets/footer-You tube.png" alt="" />
            </div>
          </div>
          <div className="footer-bottom">© 2024 noveLT. All rights served.</div>
        </footer>
      </div>
    </div>
  );
}




