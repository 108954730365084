import React, { useEffect, useState } from "react";
// import Menus from "./Menus";
// import Footers from "./Footers";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
// import { format } from "date-fns";
// import Charts2 from "./Charts/Charts2";
// import MenuTop from "./MenuTop";
// import axios from "axios";
// import ChartsInvestment from "./Charts/ChartsInvestment";
// import TimeAgo from "./TimeAgo";
// import Header2 from "./Header2";
import Swal from "sweetalert2";
import $ from "jquery";
import "../style.css";
// import { hashKey, headers } from "../../utils/headers";
import AnimatedButton from "../AnimatedButton";
import Sidebar from "./Sidebar";
import { LuAlignLeft } from "react-icons/lu";
// import styled from 'styled-components'

const Airtime1 = (props) => {
  // const {
  //   capitalizeWords,
  //   isAuthExpired,
  //   isOffcanvasActive,
  //   handleMenuToggle,
  //   token,
  //   newNotify,
  //   getNotifyCounts,
  //   notifications,
  //   fetchNotifys,
  // } = props

  // const { objectToFormData, isAdmins, setIsAdmins } = props
  // const [updateTitle, setUpdateTitle] = useState('')
  // const [updateID, setUpdateID] = useState('')
  // const [wallets, setWallets] = useState()
  // const [transactions, setTransactions] = useState([])
  // const [username, setUsername] = useState()
  // const [accountNo, setAccountNo] = useState()
  const [pinSet, setPinSet] = useState(false);
  const [network, setNetwork] = useState("");
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let record = localStorage.getItem("userDataDani");
    if (record === undefined || record == null) {
      navigate("/login");
    }
    // let records = JSON.parse(record)
    // setUsername(records.name)
    // setAccountNo(records.virtual_acct)
    let isPin = localStorage.getItem("create_pin");
    if (isPin === 1) {
      setPinSet(true);
    }
  });

  useEffect(() => {
    document.title = "Dashboard | Bill Payment";
  }, []);

  // let overNet = ''
  // const tosin = localStorage.getItem('token')
  // const getDatas = async () => {
  //   try {
  //     const response = await fetch(API_ROUTES.GET_WALLET, {
  //       method: 'POST',
  //       headers: { token: tosin },
  //     })
  //     const responseJSON = await response.json()

  //     if (responseJSON.status == 200) {
  //       console.log(responseJSON.balance)
  //       const responseData = responseJSON.balance
  //       setWallets(responseData)
  //     } else {
  //       logout()
  //     }
  //   } catch (error) {
  //     console.log('Error getting data: ', error)
  //     setWallets(0)
  //   }
  // }

  // const getTransactions = async () => {
  //   const raw = JSON.stringify({})
  //   try {
  //     const response = await fetch(API_ROUTES.GET_TRANSACTIONS, {
  //       method: 'POST',
  //       body: raw,
  //       headers: { token: tosin },
  //     })
  //     const responseJSON = await response.json()

  //     if (responseJSON.status == 200) {
  //       console.log(responseJSON.data)
  //       const responseData = responseJSON.data
  //       setTransactions(responseData)
  //     } else {
  //       logout()
  //     }
  //   } catch (error) {
  //     console.log('Error getting data: ', error)
  //     setWallets(0)
  //   }
  // }

  // useEffect(() => {
  //   getDatas()
  //   getTransactions()
  //   // fetchNotifys();
  // }, [])

  // const openLink = (page) => {
  //   navigate(`/${page}/`)
  // }

  // const showBuyDiv = (title, id) => {
  //   setUpdateTitle(title)
  //   setUpdateID(id)
  //   $('.ntwk_div').slideUp('fast')
  //   $('.amount_div, button.hidden').slideUp('fast')

  //   $('.txtEnterAmt').val('')
  //   $('.amount_div input').val('')
  // }

  const networkPicked = () => {
    let selectedNetwork = $("#network").val();
    // console.log(selectedNetwork)
    sessionStorage.setItem("selectedNetwork", selectedNetwork);
    setNetwork(selectedNetwork);
  };

  const logout = () => {
    localStorage.removeItem("userDataDani");
    // localStorage.removeItem('admin_priv');
    // localStorage.removeItem('admin_roles');
    navigate("/login");
  };

  const moveNext = () => {
    if (amount === "") {
      Swal.fire({
        title: "error",
        text: "Please enter amount!",
        icon: "failed",
      });
      return;
    }

    if (phone === "") {
      Swal.fire({
        title: "error",
        text: "Please enter phone number!",
        icon: "failed",
      });
      return;
    }

    if (network === "") {
      Swal.fire({
        title: "error",
        text: "Please select network!",
        icon: "failed",
      });
      return;
    }

    sessionStorage.setItem("airtimeAmount", amount);
    sessionStorage.setItem("airtimePhoneNumber", phone);
    navigate("/airtimesummary");
  };

  // SIDEBAR
  const [openSideBar, setOpenSideBar] = useState(true);

  const sidebar = document.querySelector(".dashboard-sidebar");
  const mobile = document.querySelector(".mobile-sidebar");

  function hideSide() {
    sidebar.classList.toggle("hide");
    setOpenSideBar((prev) => !prev);
  }
  function showSide() {
    mobile.classList.toggle("hide");
  }

  const getContainerStyle = () => {
    const baseStyle = {
      transition: ".5s ease",
    };

    if (window.innerWidth >= 1024) {
      baseStyle.marginLeft = openSideBar ? "16%" : "0px";
    } else {
      baseStyle.marginLeft = "0px";
    }

    return baseStyle;
  };

  return (
    <>
      <Sidebar logout={logout} pinSet={pinSet} type={"dashboard"} />
      <div
        className="airtime-vending-body ml-0 lg:ml-auto min-h-[100vh] max-h-[100vh] overflow-auto relative"
        style={getContainerStyle()}
      >
        <AnimatedButton className="w-min absolute top-[5%] left-[5%]">
          <LuAlignLeft
            onClick={hideSide}
            size={45}
            className="text-[#2D66A8] cursor-pointer"
            id="hide-button"
          />
        </AnimatedButton>{" "}
        <LuAlignLeft
          onClick={showSide}
          size={30}
          className="text-[#2D66A8] cursor-pointer fixed top-[4%] left-[5%]"
          id="show-button"
        />
        <div className="main h-[100%]">
          <div className="main-heading red">Airtime</div>
          <div className="purchase-option">
            <AnimatedButton>
              <button
                onClick={() => navigate("/dashboard")}
                className="buy-airtime hover:text-[#FF0000]"
              >
                Dashboard
              </button>
            </AnimatedButton>
            <AnimatedButton>
              <button
                onClick={() => navigate("/data")}
                className="pay-bill hover:text-[#FF0000]"
              >
                Buy Data
              </button>
            </AnimatedButton>
          </div>
          <div className="purchase-dashboard">
            <AnimatedButton onClick={() => moveNext()} className="next-button">
              Next
            </AnimatedButton>
            {/* <Link className="" to="/dashboard">
            <div className="wallet-back-button">
              <img src="../assets/airtime-back.png" alt="" srcset="" />
            </div>
          </Link>

          <div className="purchase-title red">Airtime</div>
        <br /> */}
            <div className="airtime-form">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                name=""
                id="phone"
                onInput={(tr) => setPhone(tr.target.value)}
                placeholder="0701***545"
              />
              <br />
              <br />
              <label htmlFor="network">Network</label>
              <select
                name="network"
                id="network"
                onChange={() => networkPicked()}
              >
                <option value="" selected disabled>
                  Choose One
                </option>
                <option value="mtn">MTN</option>
                <option value="glo">Glo</option>
                <option value="9mobile">9Mobile</option>
                <option value="airtel">Airtel</option>
              </select>
              <br />
              <br />
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                name=""
                id="amount"
                min="0"
                onInput={(tr) => setAmount(tr.target.value)}
                step="5"
                placeholder="0.00"
              />
            </div>
          </div>
          <div className="hidden lg:block fixed bottom-[15vh] right-0 z-[0]">
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img
                  src="../assets/9mobile 1.png"
                  alt=""
                  className="n-mobile"
                />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/Airtel 3.png" alt="" className="airtel" />
              </AnimatedButton>
            </div>
            <div className="flex">
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/glo.png" alt="" className="glo" />
              </AnimatedButton>
              <AnimatedButton className="cursor-pointer">
                <img src="../assets/MTN 2.png" alt="" className="mtn" />
              </AnimatedButton>
            </div>
          </div>
        </div>
        {/* <div className="background size-[80%]">
        <img src="assets/vending-background.png" alt="" className="object-contain" />
      </div> */}
      </div>
    </>
  );
};

export default Airtime1;
